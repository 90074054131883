<template>
  <div class="card pt-2 border-light servicequeue-result">
    <div class="card-body">
      <div class="row">
        <div class="col col-sm-6 col-md-6 col-lg-3">
          <p>
            <label>{{ $t('serviceQueueName') }}: </label>
            <span>{{ value.serviceQueueName }}</span>
          </p>
          <p>
            <label>{{ $t('serviceQueueNumber') }}: </label>
            <span>{{ value.startingPoint }}</span>
          </p>
          <p>
            <label>{{ $t('startingLetter') }}: </label>
            <span>{{ value.startingLetter ? value.startingLetter : '-' }}</span>
          </p>
        </div>
        <div class="col col-sm-6 col-md-6 col-lg-2">
          <p>
            <label>{{ $t('configuration') }}: </label><i
              class="far fa-edit"
              @click="$emit('on-configuration')"
            />
          </p>
          <p>
            <label>{{ $t('services') }}: </label><i
              class="far fa-edit"
              @click="$emit('on-services')"
            />
          </p>
          <p>
            <label>{{ $t('serviceQueueToMove') }}: </label><i
              class="far fa-edit"
              @click="$emit('on-queues-to-move')"
            />
          </p>
          <p v-if="value.canSendCustomNotification">
            <label>{{ $t('notifications') }}: </label><i
              class="far fa-edit"
              @click="$emit('on-configure-notifications')"
            />
          </p>
        </div>
        <div class="col col-sm-6 col-md-6 col-lg-3">
          <p>
            <label>{{ $t('daysAvailable') }}: </label><i
              class="far fa-edit"
              @click="$emit('on-business-days')"
            />
          </p>
          <p>
            <label>{{ $t('hoursAvailable') }}: </label><i
              class="far fa-edit"
              @click="$emit('on-available-hours')"
            />
          </p>
          <p>
            <label>{{ $t('automaticCall') }}: </label>
            <span>{{ $t(value.automaticCall ? 'yes' : 'no') }}</span>
            <i
              class="far fa-edit"
              @click="$emit('on-automatic-call')"
            />
          </p>
        </div>
        <div class="col col-sm-6 col-md-6 col-lg-2">
          <p>
            <label>{{ $t('autoregisterConfiguration') }}: </label><i
              class="far fa-edit"
              @click="$emit('on-autoregister-configuration')"
            />
          </p>
          <p>
            <label>{{ $t('kioskSpecialField') }}: </label>
            <span>{{ value.specialField }}</span>
          </p>
          <p v-if="displayOffice">
            <label>{{ $t('office') }} </label>
            <br />
            <span class="ml-0">{{ value.locationName }}</span>
          </p>
        </div>
        <div class="col-12 col-sm-12 col-md-12 col-lg-2 pt-4">
          <action-button
            :actions="actions"
            class="float-right"
            :text="$t('actions')"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ActionButton from '@/components/basics/buttons/ActionButton';

export default {
  name: 'ServiceQueueSearchResult',
  components: {
    ActionButton,
  },
  props: {
    value: {
      type: Object,
      default: () => {},
    },
    displayOffice: {
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    return {
      actions: [
        {
          label: this.$t('clone'),
          iconClass: 'far fa-clone',
          iconColor: '#0F16DB',
          action: () => this.$router.push({name: 'ServiceQueuesClone', params: {id: this.value.serviceQueueId}}) ,
        },
        {
          label: this.$t('delete'),
          iconClass: 'far fa-trash-alt',
          iconColor: '#FF0000',
          action: () => this.$emit('on-delete'),
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.servicequeue-result p {
  margin-bottom: 0.2rem;
}

.servicequeue-result p i {
  cursor: pointer;
  font-size: 18px;
}

.servicequeue-result label {
  font-size: 14px;
  min-width: 100px;
  font-weight: bold;
}

.servicequeue-result p i,
.servicequeue-result span {
  color: $color-primary;
  font-weight: bold;
  margin-left: 5px;
}
</style>
