<template>
  <div>
    <div class="row">
      <div class="col-sm-12 mt-2">
        <filter-multi-select
          :allow-select-all="false"
          fieldtext="name"
          fieldvalue="id"
          :is-multiple="true"
          :label="$t('associatedQueuesList')"
          :options="model.availableServiceQueues"
          :placeholder="`-- ${$t('all2')} --`"
          :value="model.selectedServiceQueues"
          @change="onSelectionChanged($event)"
        />
      </div>
    </div>
    <div class="row">
      <div class="col-md-12 mt-4">
        <base-filled-button
          bg-color="#707070"
          class="mx-0 float-left"
          icon-class="fas fa-ban"
          :on-click="() => $emit('close')"
          :text="$t('cancel')"
        />

        <base-filled-button
          class="float-right mr-0"
          icon-class="far fa-save"
          :on-click="() => onSubmit()"
          :text="$t('save')"
        />
      </div>
    </div>
  </div>
</template>

<script>
import {
  getAvailableServicesQueuesToMove as _getAvailableServicesQueuesToMove,
  updateAvailableServiceQueuesToMove as _updateAvailableServiceQueuesToMove,
} from '@/services/ServicesService';

import FilterMultiSelect from '@/components/FilterMultiSelect';

export default {
  name: 'AssociatedServiceQueuesForm',
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  components: {
    FilterMultiSelect,
  },
  data() {
    return {
      model: {
        availableServiceQueues: [],
        selectedServiceQueues: [],
      },
    };
  },
  async mounted() {
    await this.loadAutomaticServiceQueues();
  },
  methods: {
    async loadAutomaticServiceQueues() {
      await _getAvailableServicesQueuesToMove({
        serviceQueueId: this.value.serviceQueueId,
        locationConfigurationId: this.value.locationConfigurationId,
      })
        .then((response) => {
          this.model.availableServiceQueues = response.data;
          this.model.selectedServiceQueues =
            this.model.availableServiceQueues.filter((x) => x.selected);
        })
        .catch((error) => this.ShowErrorToast(error.response.data.message));
    },
    async onSubmit() {
      _updateAvailableServiceQueuesToMove({
        serviceQueueId: this.value.serviceQueueId,
        locationConfigurationId: this.value.locationConfigurationId,
        ServiceQueueIdsToMove: this.model.selectedServiceQueues.map(
          (x) => x.id,
        ),
      }).then(() => {
        this.ShowSuccessSaveToast();
        this.$emit('submitted');
        this.$emit('close');
      });
    },
    onSelectionChanged(value) {
      this.model.selectedServiceQueues = value;
    },
  },
};
</script>

<style lang="scss" scoped>
.subtitle {
  color: #ff8105;
}
</style>

