<template>
  <validation-observer ref="configurationFormValidator" novalidate>
    <div class="row">
      <div class="col-md-3">
        <validation-provider v-slot="{ errors }" rules="required|max:50">
          <base-input
            v-model="model.serviceQueueName"
            :error="errors[0]"
            :error-msg="$t('invalidField')"
            :label="`${$t('serviceQueueName')} [ESP]`"
          />
        </validation-provider>
      </div>
      <div class="col-md-3">
        <validation-provider v-slot="{ errors }" rules="max:50">
          <base-input
            v-model="model.serviceQueueNameEn"
            :error="errors[0]"
            :error-msg="$t('invalidField')"
            :label="`${$t('serviceQueueName')} [ENG]`"
          />
        </validation-provider>
      </div>
      <div class="col-md-3">
        <validation-provider v-slot="{ errors }" rules="max:50">
          <base-input
            v-model="model.servicioDescPAlias"
            :error="errors[0]"
            :error-msg="$t('invalidField')"
            :label="`${$t('publicAlias')} [ESP]`"
          />
        </validation-provider>
      </div>
      <div class="col-md-3">
        <validation-provider v-slot="{ errors }" rules="max:50">
          <base-input
            v-model="model.servicioDescPAliasEn"
            :error="errors[0]"
            :error-msg="$t('invalidField')"
            :label="`${$t('publicAlias')} [ENG]`"
          />
        </validation-provider>
      </div>
    </div>
    <hr />
    <div class="row">
      <div class="col-md-3">
        <filter-numeric
          v-model="model.waitingTime"
          :max="480"
          :min="1"
          title="waitTimeInMinuts"
        />
      </div>
      <div class="col-md-3">
        <filter-numeric
          v-model="model.attentionGoalTime"
          :max="480"
          :min="0"
          title="targetAttentionTime"
        />
      </div>
      <div class="col-md-3">
        <filter-color
          v-model="model.startingPointColor"
          :label="$t('colorInScreen')"
        />
      </div>
      <div class="col-md-3">
        <filter-numeric
          v-model="model.startingPoint"
          :max="100000"
          :min="1"
          title="serviceQueueStartingNumber"
        />
      </div>
      <div class="col-md-3">
        <validation-provider v-slot="{ errors }" rules="max:3">
          <base-input
            v-model="model.startingLetter"
            :error="errors[0]"
            :error-msg="$t('invalidField')"
            :label="`${$t('serviceQueueStartingLetter')}`"
          />
        </validation-provider>
      </div>
      <div v-if="canEditSurveys" class="col-md-3">
        <filter-multi-select
            fieldtext="name"
            fieldvalue="id"
            :is-multiple="false"
            :label="$t('survey')"
            :options="surveys"
            :placeholder="`-- ${$t('select')} --`"
            :value="selectedSurvey"
            :clearable="true"
            @change="onSurveyChange($event)"
          />
      </div>
      <div class="col-md-3 pt-4 mt-2">
        <b-form-checkbox v-model="model.automaticTransfer" switch :disabled="!isAdministrator">
          {{ $t('automaticTransfer') }}
        </b-form-checkbox>
      </div>
      <div v-if="model.automaticTransfer" class="col-md-3">
        <filter-select
          v-model="model.automaticTransferQueueId"
          fieldtext="name"
          fieldvalue="id"
          :label="$t('automaticTransfer')"
          :options="collection.queues"
        />
      </div>
    </div>
    <hr />
    <div class="row">
      <div class="col-md-3">
        <b-form-checkbox v-model="model.automaticCall" switch>
          {{ $t('automaticCall') }}
        </b-form-checkbox>
      </div>
      <div class="col-md-3">
        <b-form-checkbox v-model="model.autoSequencial" switch>
          {{ $t('autoSequential') }}
        </b-form-checkbox>
      </div>
      <div class="col-md-3">
        <b-form-checkbox v-model="model.visibleInRegistration" switch>
          {{ $t('visibleInRegistry') }}
        </b-form-checkbox>
      </div>
      <div class="col-md-3">
        <b-form-checkbox v-model="model.visibleInHandler" switch>
          {{ $t('visibleInHandler') }}
        </b-form-checkbox>
      </div>
      <div class="col-md-3">
        <b-form-checkbox v-model="model.canNotAssignOther" switch>
          {{ $t('canAssignToAnotherUser') }}
        </b-form-checkbox>
      </div>
      <div class="col-md-3">
        <b-form-checkbox v-model="model.considerVisitTo" switch>
          {{ $t('considerVisitTo') }}
        </b-form-checkbox>
      </div>
      <div class="col-md-3">
        <b-form-checkbox v-model="model.isHoldQueue" switch>
          {{ $t('waitingQueue') }}
        </b-form-checkbox>
      </div>
      <div class="col-md-3">
        <b-form-checkbox v-model="model.isCommentRequired" switch>
          {{ $t('commentRequired2') }}
        </b-form-checkbox>
      </div>
      <div class="col-md-3">
        <b-form-checkbox v-model="model.isVideoCall" switch>
          {{ $t('videoCall2') }}
        </b-form-checkbox>
      </div>
      <div class="col-md-3">
        <b-form-checkbox v-model="model.transferVisitTo" switch>
          {{ $t('transferVisitTo') }}
        </b-form-checkbox>
      </div>
      <div class="col-md-3">
        <b-form-checkbox v-model="model.instantRecordService" switch>
          {{ $t('instantRecord') }}
        </b-form-checkbox>
      </div>
      <div class="col-md-3">
        <b-form-checkbox v-model="model.hiddenInReports" switch>
          {{ $t('hiddenInReports') }}
        </b-form-checkbox>
      </div>
      <div class="col-md-3">
        <b-form-checkbox v-model="model.canSendCustomNotification" :disabled="isCustomNotificationsDisabled" switch>
          {{ $t('customNotifications') }}
        </b-form-checkbox>
      </div>
      <div class="col-md-3">
        <b-form-checkbox v-model="model.serviceRequiredOnTransfer" switch>
          {{ $t('serviceRequiredOnTransfer') }}
        </b-form-checkbox>
      </div>
      <div class="col-md-3">
        <b-form-checkbox v-model="model.autoSelectSpecialist" switch>
          {{ $t('autoSelectSpecialist') }}
        </b-form-checkbox>
      </div>
      <div class="col-md-3">
        <b-form-checkbox v-model="model.excludeFromHomeDashboard" switch>
          {{ $t('excludeFromHomeDashboard') }}
        </b-form-checkbox>
      </div>
      <div class="col-md-3">
        <b-form-checkbox :disabled="!haveCommunicationModule" :checked="model.isChat" @change="onIsChatChange()" switch>
          {{ $t('useChat') }}
        </b-form-checkbox>
      </div>
      <div class="col-md-3">
        <b-form-checkbox :disabled="!haveCommunicationModule" :checked="model.isCall" @change="onIsCallChange()" switch>
          {{ $t('useCall') }}
        </b-form-checkbox>
      </div>

      <div class="col-md-3">
        <b-form-checkbox v-model="model.individualCall" switch>
          {{ $t('individualCall') }}
        </b-form-checkbox>
      </div>
    </div>
    <hr />
    <div class="row">
      <div class="col-md-3">
        <filter-numeric
          v-model="model.totalShiftPerService"
          :max="9999"
          :min="0"
          title="totalTurnsPerQueue"
        />
      </div>
      <div class="col-md-3">
        <filter-numeric
          v-model="model.alertWithTurnCount"
          :max="9999"
          :min="0"
          title="alertWhenX"
        />
      </div>
      <div class="col-md-3">
        <filter-numeric
          v-model="model.displayInMonitor"
          :max="999"
          :min="0"
          title="displayInMonitor"
        />
      </div>
      <div class="col-md-3">
        <filter-numeric
          v-model="model.priorityInTurn"
          :max="999"
          :min="0"
          title="priorityWhenCallingTurn"
          :clearable="true"
        />
      </div>
      <div class="col-md-3">
        <b-form-checkbox
          v-model="model.servicioEnabled"
          switch
        >
          {{ $t('available') }}
        </b-form-checkbox>
      </div>
    </div>
    <hr />
    <div class="row">
      <div class="col-md-12 mt-4">
        <base-filled-button
          bg-color="#707070"
          class="mx-0 float-left"
          icon-class="fas fa-ban"
          :on-click="() => $emit('close')"
          :text="$t('cancel')"
        />

        <base-filled-button
          class="float-right mr-0"
          icon-class="far fa-save"
          :on-click="() => onSubmit()"
          :text="$t('save')"
        />
      </div>
    </div>
  </validation-observer>
</template>

<script>

import IdentityPermission from '@/constants/IdentityPermission';

import {
  getServiceQueueConfiguration as _getServiceQueueConfiguration,
  updateServiceQueueConfiguration as _updateServiceQueueConfiguration,
} from '@/services/ServicesService';

import {
  getSurveyList as _getSurveyList
} from '@/services/SurveyService'

import {
  haveCommunicationModuleEnabled as _haveCommunicationModuleEnabled,
  getServiceQueues as _getServiceQueues
} from '@/services/LocationService';

import BaseInput from '@/components/BaseInput.vue';
import FilterMultiSelect from '@/components/FilterMultiSelect';
import SystemRoles from '@/constants/SystemRoles';
import { mapState } from 'vuex';

export default {
  name: 'ServiceQueueConfigurationForm',
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  components: {
    BaseInput,
    FilterMultiSelect
  },
  data() {
    return {
      model: {
        servicioDesc: null,
        servicioDescEn: null,
        servicioDescPAlias: null,
        servicioDescPAliasEn: null,
        waitingTime: null,
        attentionGoalTime: null,
        startingPoint: null,
        autoSequencial: null,
        register: null,
        visibleInHandler: null,
        canNotAssignOther: null,
        verifyVisitingUser: null,
        hold: null,
        commentRequired: null,
        isVideoCall: null,
        transferVisitTo: null,
        instantRecordService: null,
        totalShiftPerService: null,
        alert: null,
        monitor: null,
        servicioEnabled: null,
        hiddenInReports: null,
        priorityInTurn: null,
        canSendCustomNotification: null,
        serviceRequiredOnTransfer: false,
        surveyId: null,
        autoSelectSpecialist: false,
        excludeFromHomeDashboard: false,
        isChat: false,
        isCall: false,
        individualCall: false,
        automaticTransfer: false,
        automaticTransferQueueId: null,
      },
      surveys: [],
      selectedSurvey: null,
      haveCommunicationModule: false,
      collection: {
        queues: []
      }
    };
  },
  computed: {
    ...mapState('$_user', ['profile']),
    isCustomNotificationsDisabled() {
      return !this.userHasPermissions(IdentityPermission.service.queue.custom.notification.manage);
    },
    canEditSurveys() {
      return this.userHasPermissions(IdentityPermission.service.queue.survey.edit);
    },
    isAdministrator() {
      return this.profile.roleId === SystemRoles.administrator;
    }
  },
  async mounted() {
    await this.loadConfiguration();
    _getServiceQueues(this.value.locationConfigurationId).then(({data}) => this.collection.queues = data);
    await _haveCommunicationModuleEnabled(this.value.locationConfigurationId).then(({ data }) => {
      this.haveCommunicationModule = data;
    });
  },
  methods: {
    async onSurveyChange(value) {
      if (value)
        this.selectedSurvey = [value];
      else
        this.selectedSurvey = null;
    },
    async loadConfiguration() {
      await _getServiceQueueConfiguration({
        serviceQueueId: this.value.serviceQueueId,
        locationConfigurationId: this.value.locationConfigurationId,
      })
        .then(async (response) => {
          this.model = response.data;

          await _getSurveyList([this.value.locationConfigurationId])
            .then((response) => {
              this.surveys = (response.data || []).sort((a,b) => b.surveyId - a.surveyId);

              this.selectedSurvey = this.surveys.filter(
              (p) => {
                return p.id === this.model.surveyId;
              });
            })
            .catch((error) => this.ShowErrorToast(error.response.data.message));
        })
        .catch((error) => this.ShowErrorToast(error.response.data.message));
    },
    onIsChatChange() {
      if (!this.model.isChat) {
        this.model.isCall = false;
      }
      this.model.isChat = !this.model.isChat;
    },
    onIsCallChange() {
      if (!this.model.isCall) {
        this.model.isChat = false;
      }
      this.model.isCall = !this.model.isCall;
    },
    async onSubmit() {
      const isValid = await this.$refs.configurationFormValidator.validate();

      if (!isValid) {
        return;
      }

      this.model.surveyId = this.selectedSurvey?.length ? this.selectedSurvey[0].id : null;

      _updateServiceQueueConfiguration(this.model).then(() => {
        this.ShowSuccessSaveToast();
        this.$emit('submitted');
        this.$emit('close');
      });
    },
  },
};
</script>

