<template>
  <section>
    <content-header
      v-if="showMaintenance"
      :title="$t('serviceQueueGroupMaintenance')"
    />
    <div v-if="showMaintenance" class="container-fluid">
      <div class="main-page-container">
        <div class="row">
          <sub-header :title="$t('filterServiceQueueGroups')" />
        </div>
        <validation-observer ref="serviceQueueGroupFormRef" novalidate>
          <filter-manager
            ref="filterComponent"
            v-model="selectedFilters"
            class="mb-4"
            :display-advanced="false"
            :filters="filtersConfig"
            :search-text="$t('filter')"
            @search="search(1)"
          />
        </validation-observer>
      </div>

      <separator class="my-4" :text="$t('listOfGroups')" />
      <div class="row mt-4 mb-3">
        <div class="col-sm-12">
          <base-filled-button
            class="float-right"
            icon-class="fas fa-plus"
            :on-click="openCreateModal"
            :text="$t('create')"
            :disabled="!selectedCompanies.length"
          />
        </div>
      </div>
      <CustomNotFound v-if="noResultsFound" :text="$t('noResultsFound')" />
      <div class="row">
        <div
          v-for="(serviceQueueGroup, index) in serviceQueueGroups.data"
          :key="index"
          class="mt-3 col-12"
        >
          <search-result
            :display-office="!isMultiOffice"
            :value="serviceQueueGroup"
            @on-edit="openEditModal(serviceQueueGroup)"
            @on-delete="openDeleteModal(serviceQueueGroup)"
            @on-link="openLinkQueueModal(serviceQueueGroup)"
          />
        </div>
      </div>
      <custom-pagination
        v-if="serviceQueueGroups.count > 0"
        id="pgPager"
        class="my-4"
        :data="serviceQueueGroups"
        :page-size="pageSize"
        @pagination-go-page="search($event)"
        @pagination-rows-per-page="search(pageIndex, $event)"
      />
    </div>
    <custom-modal
      v-model="showServiceGroupQueueGroupFormModal"
      size="md"
      :title="!editModel ? $t('createServiceQueueGroup') : $t('editServiceQueueGroup')"
    >
      <service-queue-group-form
        v-if="showServiceGroupQueueGroupFormModal"
        v-model="editModel"
        :company-id="editModel ? editModel.companyId : selectedCompanies[0]"
        @close="showServiceGroupQueueGroupFormModal = false"
        @submitted="search()"
      />
    </custom-modal>
    <custom-modal
      v-model="showServiceGroupLinkedQueuesModal"
      size="md"
      :title="$t('queueSelectionAccordingToOffice')"
    >
      <service-queue-group-linked-queues
        v-if="showServiceGroupLinkedQueuesModal"
        v-model="linkedQueuesModel"
        @close="showServiceGroupLinkedQueuesModal = false"
      />
    </custom-modal>

    <BigLoader v-if="loading" />
    <AlertMessage
      v-if="!showMaintenance && !loading"
      :message="$t('selectOfficeForTheMaintenance')"
      style="margin-top: 150px"
    />
    <DeleteModal
      v-model="showDeleteModal"
      error-message="groupNameIsNotCorrect"
      :item="editModel"
      label="serviceQueueGroupName"
      name="serviceQueueGroupNameESP"
      @on-confirm="onDelete()"
    />
  </section>
</template>

<script>
import CustomPagination from '@/components/CustomPagination';
import CustomModal from '@/components/basics/modal/CustomModal';
import CustomNotFound from '@/components/basics/alerts/CustomNotFound';
import SearchResult from './components/SearchResult';
import FilterManager from '@/components/FilterManager';
import BigLoader from '@/components/BigLoader';
import contentHeader from '@/components/Header';
import SubHeader from '@/components/SubHeader';
import IdentityPermission from '@/constants/IdentityPermission';
import Separator from '@/components/Separator';
import { getCompanies as _getCompanies } from '@/services/CompanyService';
import {
  getServiceQueueGroups as _getServiceQueueGroups,
  deleteServiceQueueGroup as _deleteServiceQueueGroup
  } from '@/services/ServicesService';
import { modulesName } from '@/store';
import AlertMessage from '@/components/basics/alerts/AlertMessage';

import ServiceQueueGroupForm from './components/ServiceQueueGroupForm';
import ServiceQueueGroupLinkedQueues from './components/ServiceQueueGroupLinkedQueues';

import DeleteModal from '@/components/DeleteModal';

import { mapGetters } from 'vuex';

export default {
  name: 'ServiceQueueGroups',
  components: {
    CustomPagination,
    CustomNotFound,
    SearchResult,
    FilterManager,
    contentHeader,
    SubHeader,
    Separator,
    AlertMessage,
    BigLoader,
    ServiceQueueGroupForm,
    ServiceQueueGroupLinkedQueues,
    CustomModal,
    DeleteModal
  },
  data() {
    return {
      filters: {
        companies: [],
      },
      selectedFilters: {
        company: [],
        name: '',
      },
      serviceQueueGroups: {
        data: [],
        totalItemCount: 0,
        count: 0,
        pageCount: 0,
        currentPage: 1,
      },
      pageSize: 10,
      pageIndex: 1,
      noResultsFound: false,
      selectedServiceQueueGroup: null,
      showMaintenance: false,

      showServiceGroupQueueGroupFormModal: false,
      showServiceGroupLinkedQueuesModal: false,
      showDeleteModal: false,
      loading: false,

      selectedCompanies: [],
      editModel: null,

      linkedQueuesModel: {
        companyId: null,
        serviceQueueGroupId: null
      }
    };
  },
  mounted() {
    this.getCompanies();
    this.showMaintenance = true;
  },
  computed: {
    ...mapGetters(modulesName.userModuleName, ['currentActiveLocation']),
    filtersConfig() {
      return [
        {
          rules: 'required',
          name: 'company',
          label: this.$t('company'),
          component: 'FilterSelect',
          options: this.filters.companies,
          placeholder: `-- ${this.$t('select')} --`,
          fieldtext: 'text',
          fieldvalue: 'value',
          clearable: true,
          isMultiple: true,
          class: 'col-sm-12 col-md-4 col-lg-3',
          error: this.$t('mustselectcompany'),
          initialValue: this.selectedFilters.company,
          allowSelectAll: false,
        },
        {
          rules: '',
          name: 'name',
          label: this.$t('name'),
          component: 'BaseInput',
          placeholder: this.$t('name'),
          fieldtext: 'name',
          fieldvalue: 'id',
          class: 'col-sm-12 col-md-4 col-lg-3',
          initialValue: this.selectedFilters.name,
          value: this.selectedFilters.name,
        },
      ];
    },
    isMultiOffice() {
      return this.userHasPermissions(IdentityPermission.multi.office);
    },
    locationConfigurationId() {
      return this.isMultiOffice
        ? Number(this.currentActiveLocation.locationConfigurationId)
        : this.selectedFilters.location.length > 0
        ? this.selectedFilters.location[0].id
        : null;
    },
  },
  watch: {
    currentActiveLocation() {
      this.showMaintenance = false;
      this.loading = true;
      this.noResultsFound = false;
      setTimeout(() => {
        this.selectedFilters.company = null;
        this.selectedFilters.name = '';
        this.serviceQueueGroups = {
          data: [],
          totalItemCount: 0,
          count: 0,
          pageCount: 0,
          currentPage: 1,
        };
        this.loading = false;
        this.showMaintenance = true;
      }, 1200);
    },
  },
  methods: {
    async getCompanies() {
      await _getCompanies()
        .then((response) => {
          this.filters.companies = response.data || [];
        })
        .catch((error) => this.ShowErrorToast(error.response.data.message));
    },
    openCreateModal() {
      this.showServiceGroupQueueGroupFormModal = true;
      this.editModel = null;
    },
    openDeleteModal(data) {
      this.showDeleteModal = true;
      this.editModel = { ...data };
    },
    openLinkQueueModal(data) {
      this.linkedQueuesModel = {
        companyIds: this.selectedCompanies,
        serviceQueueGroupId: data.serviceQueueGroupId
      };

      this.showServiceGroupLinkedQueuesModal = true;
    },
    openEditModal(data) {
      this.showServiceGroupQueueGroupFormModal = true;
      this.editModel = { ...data };
    },
    onDelete() {
      _deleteServiceQueueGroup(this.editModel.serviceQueueGroupId)
        .then(async () => {
          this.ShowSuccessToast(this.$t('successfullyDeleted'));
          this.showDeleteModal = false;
          await this.search();
          this.editModel = null;
        })
        .catch((error) => this.ShowErrorToast(error.response.data.message));
    },
    async search(pageIndex = null, pageSize = null) {
      const isValid = await this.$refs.serviceQueueGroupFormRef.validate();

      if (!isValid) {
        return;
      }

      this.selectedCompanies = this.selectedFilters.company;

      this.pageSize = pageSize || this.pageSize;
      this.pageIndex = pageIndex || this.pageIndex;

      const payload = {
        companyIds: this.selectedFilters.company,
        serviceQueueGroupName: this.selectedFilters.name || null,
        pageIndex: this.pageIndex,
        pageSize: this.pageSize,
      };

      await _getServiceQueueGroups(payload)
        .then(({ data }) => {
          this.serviceQueueGroups = data;
          this.noResultsFound = !this.serviceQueueGroups.count;
        })
        .catch((error) => this.ShowErrorToast(error.response.data.message));
    },
  },
};
</script>
<style lang="scss" scoped>
</style>
