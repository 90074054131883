<template>
  <div>
    <div class="col-md-12">
      <div class="row">
        <div class="col-md-12">
          <label class="mb-0" for="hourFrom">{{ $t('hourFrom') }}: </label>
          <base-datetime-picker
            id="hourFrom"
            v-model="value.hourFrom"
            class="theme-orange"
            icon-class="clock"
            type="time"
            @change="value.hourFrom = $event"
          />
        </div>
        <div class="col-md-12">
          <label class="mb-0" for="hourTo">{{ $t('hourTo') }}: </label>
          <base-datetime-picker
            id="hourTo"
            v-model="value.hourTo"
            class="theme-orange"
            icon-class="clock"
            type="time"
            @change="value.hourTo = $event"
          />
        </div>
        <div class="col-md-12 text-right mt-3">
          <base-filled-button
            bg-color="#707070"
            class="mx-0 float-left"
            icon-class="fas fa-ban"
            :on-click="() => $emit('close')"
            :text="$t('cancel')"
          />

          <base-filled-button
            class="float-right"
            icon-class="far fa-save"
            :on-click="() => onSubmit()"
            :text="$t('save')"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { updateServiceQueueServiceHour as _updateServiceQueueServiceHour } from '@/services/ServicesService';
import moment from 'moment';

export default {
  name: 'ServiceQueueBusinessDays',
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  mounted() {},
  methods: {
    async onSubmit() {
      const model = {
        serviceQueueId: this.value.serviceQueueId,
        locationConfigurationId: this.value.locationConfigurationId,
        hourFrom: this.value.hourFrom
          ? moment(this.value.hourFrom).format('LTS')
          : null,
        hourTo: this.value.hourTo
          ? moment(this.value.hourTo).format('LTS')
          : null,
      };

      await _updateServiceQueueServiceHour(model).then(() => {
        this.ShowSuccessSaveToast();
        this.$emit('submitted');
        this.$emit('close');
      });
    },
  },
};
</script>

