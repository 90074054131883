<template>
  <validation-observer ref="autoregisterConfigurationFormValidator" novalidate>
    <div class="row">
      <div class="col-md-3">
        <validation-provider v-slot="{ errors }" rules="max:50">
          <base-input
            v-model="model.autoRegisterQueueName"
            :error="errors[0]"
            :error-msg="$t('invalidField')"
            :label="`${$t('autoRegisterServiceQueue')} [ESP]`"
          />
        </validation-provider>
      </div>
      <div class="col-md-3">
        <validation-provider v-slot="{ errors }" rules="max:50">
          <base-input
            v-model="model.autoRegisterQueueNameEn"
            :error="errors[0]"
            :error-msg="$t('invalidField')"
            :label="`${$t('autoRegisterServiceQueue')} [ENG]`"
          />
        </validation-provider>
      </div>
      <div class="col-md-3">
        <validation-provider v-slot="{ errors }" rules="max:50">
          <base-input
            v-model="model.servicioDescPAlias"
            :error="errors[0]"
            :error-msg="$t('invalidField')"
            :label="`${$t('publicAlias')} [ESP]`"
          />
        </validation-provider>
      </div>
      <div class="col-md-3">
        <validation-provider v-slot="{ errors }" rules="max:50">
          <base-input
            v-model="model.servicioDescPAliasEn"
            :error="errors[0]"
            :error-msg="$t('invalidField')"
            :label="`${$t('publicAlias')} [ENG]`"
          />
        </validation-provider>
      </div>
      <div class="col-md-3">
        <validation-provider v-slot="{ errors }" rules="max:1">
          <base-input
            v-model="model.specialField"
            :error="errors[0]"
            :error-msg="$t('invalidField')"
            :label="$t('kioskSpecialField')"
          />
        </validation-provider>
      </div>
    </div>
    <hr />
    <div class="row">
      <div class="col-md-3">
        <div class="row">
          <div class="col-sm-12">
            <label class="mb-0" for="hourFrom">{{ $t('hourFrom') }}: </label>
            <base-datetime-picker
              id="hourFrom"
              v-model="model.autoRegisterHourFrom"
              class="theme-orange"
              icon-class="clock"
              type="time"
              @change="model.autoRegisterHourFrom = $event"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12">
            <label class="mb-0" for="hourTo">{{ $t('hourTo') }}: </label>
            <base-datetime-picker
              id="hourTo"
              v-model="model.autoRegisterHourTo"
              class="theme-orange"
              icon-class="clock"
              type="time"
              @change="model.autoRegisterHourTo = $event"
            />
          </div>
        </div>
      </div>
      <div class="col-md-3">
        <b-form-checkbox
          v-model="model.isAutoRegistration"
          class="chk-vertical-center"
          switch
        >
          {{ $t('autoRegister') }}
        </b-form-checkbox>
      </div>
      <div class="col-md-3">
        <b-form-checkbox
          v-model="model.canSendMessage"
          class="chk-vertical-center"
          switch
        >
          {{ $t('messageSending') }}
        </b-form-checkbox>
      </div>
      <div class="col-md-3">
        <b-form-checkbox
          v-model="model.respectCitedHour"
          class="chk-vertical-center"
          switch
        >
          {{ $t('respectCitedHour') }}
        </b-form-checkbox>
      </div>
    </div>
    <hr />
    <div class="row">
      <div class="col-md-3">
        <filter-multi-select
          class="font-normal"
          fieldtext="name"
          fieldvalue="id"
          clearable
          :label="$t('serviceInAppointment')"
          :options="model.appointmentServiceQueues"
          :placeholder="$t('select')"
          :value="model.appointmentServiceQueue"
          @change="onAppointmentServiceQueueChanged($event)"
        />
      </div>
      <div class="col-md-3">
        <filter-multi-select
          class="font-normal"
          fieldtext="name"
          fieldvalue="id"
          :label="$t('expressOrRegular')"
          :options="model.serviceExpressTypes"
          :placeholder="$t('select')"
          :value="model.serviceExpressType"
          @change="onServiceExpressTypeChanged($event)"
        />
      </div>
      <div class="col-md-3">
        <filter-numeric
          v-model="model.autoRegistrationOrder"
          :max="9999"
          :min="0"
          title="autoregistrationOrder"
        />
      </div>
      <div class="col-md-3">
        <filter-numeric
          v-model="model.alertOptionWhen"
          :max="9999"
          :min="0"
          title="activateAlertOption"
        />
      </div>
    </div>
    <hr />
    <div class="row">
      <div class="col-md-12 mt-4">
        <base-filled-button
          bg-color="#707070"
          class="mx-0 float-left"
          icon-class="fas fa-ban"
          :on-click="() => $emit('close')"
          :text="$t('cancel')"
        />

        <base-filled-button
          class="float-right mr-0"
          icon-class="far fa-save"
          :on-click="() => onSubmit()"
          :text="$t('save')"
        />
      </div>
    </div>
  </validation-observer>
</template>

<script>
import {
  getServiceQueueAutoRegisterConfiguration as _getServiceQueueAutoRegisterConfiguration,
  updateServiceQueueAutoRegisterConfiguration as _updateServiceQueueAutoRegisterConfiguration,
} from '@/services/ServicesService';
import moment from 'moment';

import BaseInput from '@/components/BaseInput.vue';
import FilterMultiSelect from '@/components/FilterMultiSelect';

export default {
  name: 'ServiceQueueAutoRegisterConfigurationForm',
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  components: {
    BaseInput,
    FilterMultiSelect,
  },
  data() {
    return {
      model: {
        autoRegisterQueueName: null,
        autoRegisterQueueNameEn: null,
        servicioDescPAlias: null,
        servicioDescPAliasEn: null,
        isAutoRegistration: null,
        canSendMessage: null,
        respectCitedHour: null,
        autoRegisterHourFrom: null,
        autoRegisterHourTo: null,
        appointmentServiceQueues: [],
        appointmentServiceQueue: null,
        appointmentServiceQueueId: null,
        alertOptionWhen: null,
        serviceExpressTypes: [],
        serviceExpressType: null,
        serviceExpressTypeId: null,
        autoRegistrationOrder: null,
      },
    };
  },
  async mounted() {
    await this.loadConfiguration();
  },
  methods: {
    async loadConfiguration() {
      await _getServiceQueueAutoRegisterConfiguration({
        serviceQueueId: this.value.serviceQueueId,
        locationConfigurationId: this.value.locationConfigurationId,
      })
        .then((response) => {
          this.model = response.data;

          if (this.model.appointmentServiceQueueId)
            this.model.appointmentServiceQueue =
              this.model.appointmentServiceQueues.filter(
                (x) => x.id == this.model.appointmentServiceQueueId,
              );

          if (this.model.serviceExpressTypeId)
            this.model.serviceExpressType =
              this.model.serviceExpressTypes.filter(
                (x) => x.id == this.model.serviceExpressTypeId,
              );
        })
        .catch((error) => this.ShowErrorToast(error.response.data.message));
    },
    async onSubmit() {
      const isValid =
        await this.$refs.autoregisterConfigurationFormValidator.validate();

      if (!isValid) {
        return;
      }

      let timePattern = /^\d\d:\d\d:\d\d$/;

      _updateServiceQueueAutoRegisterConfiguration({
        ...this.model,
        autoRegisterHourFrom: this.model.autoRegisterHourFrom
          ? !timePattern.test(this.model.autoRegisterHourFrom)
            ? moment(this.model.autoRegisterHourFrom).format('HH:mm:ss')
            : this.model.autoRegisterHourFrom
          : null,
        autoRegisterHourTo: this.model.autoRegisterHourTo
          ? !timePattern.test(this.model.autoRegisterHourTo)
            ? moment(this.model.autoRegisterHourTo).format('HH:mm:ss')
            : this.model.autoRegisterHourTo
          : null,
      }).then(() => {
        this.ShowSuccessSaveToast();
        this.$emit('submitted');
        this.$emit('close');
      });
    },
    onAppointmentServiceQueueChanged(value) {
      this.model.appointmentServiceQueueId = value ? value.id : null;
    },
    onServiceExpressTypeChanged(value) {
      this.model.serviceExpressTypeId = value ? value.id : null;
    },
  },
};
</script>

<style lang="scss" scoped>
.chk-vertical-center {
  margin-top: 2.2rem !important;
}
</style>

