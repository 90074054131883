<template>
  <validation-observer ref="serviceQueueFormRef" novalidate>
    <div class="row">
      <div class="col-md-12">
        <validation-provider v-slot="{ errors }" rules="required|max:50">
          <base-input
            v-model="model.serviceQueueName"
            :error="errors[0]"
            :error-msg="$t('invalidField')"
            :label="$t('serviceQueueName')"
          />
        </validation-provider>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12 col-md-6">
        <validation-provider v-slot="{ errors }" rules="max:3">
          <base-input
            v-model="model.startingLetter"
            :error="errors[0]"
            :error-msg="$t('invalidField')"
            :label="$t('serviceQueueStartingLetter')"
            :max-length="3"
          />
        </validation-provider>
      </div>
      <div class="col-sm-12 col-md-6">
        <filter-numeric
          v-model="model.startingPoint"
          :max="100000"
          :min="1"
          title="serviceQueueStartingNumber"
        />
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12">
        <button
          class="btn btn-primary float-right"
          variant="primary"
          @click="onSubmit()"
        >
          <i class="far fa-save" /> {{ $t('save') }}
        </button>
      </div>
    </div>
  </validation-observer>
</template>

<script>
import BaseInput from '@/components/BaseInput.vue';

import { createOrUpdateServiceQueue as _createOrUpdateServiceQueue } from '@/services/ServicesService';

export default {
  name: 'ServiceQueueForm',
  components: { BaseInput },
  props: {
    locationConfigurationId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      model: {
        serviceQueueName: null,
        startingLetter: null,
        startingPoint: null,
      },
    };
  },
  mounted() {
    this.resetModel();
  },
  methods: {
    async onSubmit() {
      const isValid = await this.$refs.serviceQueueFormRef.validate();

      if (!isValid) {
        return;
      }

      const model = {
        locationConfigurationId: this.locationConfigurationId,
        serviceQueueName: this.model.serviceQueueName,
        startingLetter: this.model.startingLetter,
        startingPoint: this.model.startingPoint
          ? Number(this.model.startingPoint)
          : null,
      };

      await _createOrUpdateServiceQueue(model)
        .then(() => {
          this.ShowSuccessSaveToast();
          this.$emit('submitted');
          this.$emit('close');
        })
        .catch((error) => this.ShowErrorToast(error.response.data.message));
    },
    resetModel() {
      this.model = {
        serviceQueueName: null,
        startingLetter: null,
        startingPoint: null,
      };
    },
  },
};
</script>
<style lang="scss" scoped>
</style>
