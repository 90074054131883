<template>
  <div class="container">
    <div class="row list-container">
      <div class="col-sm-12 d-table office-group-list" v-for="(location, index) in model" :key="index">
        <div class="d-table-row header">
          <div class="d-table-cell office-name"><label>{{ $t('officeName') }}</label></div>
          <div class="d-table-cell queue-name"><label>{{ $t('queue') }}</label></div>
          <div class="d-table-cell selection"><label>{{ $t('selection') }}</label></div>
        </div>
        <div class="d-table-row data-row" v-for="(detail, subIndex) in location.details" :key="subIndex">
          <div class="d-table-cell office-name"><label>{{ location.locationName }}</label></div>
          <div class="d-table-cell queue-name"><label>{{ detail.serviceQueueNameESP }}</label></div>
          <div class="d-table-cell selection">
            <b-form-checkbox
              v-model="detail.serviceQueueEnabled"
              class="chk-available"
              switch
            >
            </b-form-checkbox>
          </div>
        </div>
      </div>
    </div>
    <div class="row footer pt-3">
      <div class="col-sm-12">
        <button
          class="btn btn-primary float-right"
          variant="primary"
          @click="onSubmit()"
        >
          <i class="far fa-save" /> {{ $t('save') }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { getServiceQueueGroupLinkedQueues as _getServiceQueueGroupLinkedQueues,
         updateServiceQueueGroupLinkedQueues as _updateServiceQueueGroupLinkedQueues } from '@/services/ServicesService';

export default {
  name: 'ServiceQueueGroupLinkedQueues',
  props: {
    value: {
      type: Object,
      required: true
    },
  },
  data() {
    return {
      model: []
    };
  },
  async mounted() {
    this.loadLinkedQueues();
  },
  methods: {
    async loadLinkedQueues() {
      await _getServiceQueueGroupLinkedQueues(this.value)
        .then(({data}) => {
          this.model = data;
        })
        .catch((error) => this.ShowErrorToast(error.response.data.message));
    },
    async onSubmit() {
      const payload = this.model.map(({details}) => details.filter(r => r.serviceQueueEnabled)).flat();

      await _updateServiceQueueGroupLinkedQueues({
        serviceQueueGroupId: this.value.serviceQueueGroupId,
        details: payload
      })
        .then(() => {
          this.ShowSuccessSaveToast();
          this.$emit('submitted');
          this.$emit('close');
        })
        .catch((error) => this.ShowErrorToast(error.response.data.message));
    },
  },
};
</script>
<style lang="scss" scoped>
  .container {
    margin-top: -16px;
    padding-left: 0;
    padding-right: 0;
  }

  .list-container {
    overflow-y: auto;
    height: 420px;
  }

  .office-group-list {
    border: 1px solid $color-primary;
    margin: 5px 15px;
    border-radius: 4px;
    background-color: rgba(255,129,5,0.05);
    padding: 0px;
    max-width: 94%;
    padding: 10px 0px;

    margin-left: 15px;
    padding-left: 10px;
    margin-right: 0px;

    & .header {
      font-weight: bold;
      padding-bottom: 5px;
      height: 24px;
    }
    & .data-row {
      color: $color-primary;
      line-height: 14px;

    }
  }

  .d-table-cell {
    & label {
      margin: auto!important;
      vertical-align: middle;
    }

    &.office-name {
      width: 160px;
      text-align: left;
      line-height: 14px;
    }

    &.queue-name {
      width: 175px;
      text-align: left;
      line-height: 14px;
    }

    &.selection {
      width: 91px;
      text-align: center;
      line-height: 14px;
    }
  }

  .footer {
    border-top: 1px solid rgba(0, 0, 0, 0.2);
  }
</style>
