<template>
  <section>
    <content-header
      v-if="showMaintenance"
      :title="$t('serviceQueueMaintenance')"
    />
    <div v-if="showMaintenance" class="container-fluid">
      <div class="main-page-container">
        <div class="row">
          <sub-header :title="$t('filterServiceQueues')" />
        </div>
        <filter-manager
          ref="filterComponent"
          v-model="selectedFilters"
          class="mb-4"
          :display-advanced="false"
          :filters="filtersConfig"
          :search-text="$t('filter')"
          @search="search(1)"
        />
      </div>

      <separator class="my-4" :text="$t('listOfServiceQueues')" />
      <div class="row mt-4 mb-3">
        <div class="col-sm-12">
          <base-filled-button
            class="float-right"
            icon-class="fas fa-plus"
            :on-click="() => (showServiceQueueFormModal = true)"
            :text="$t('create')"
          />
        </div>
      </div>
      <CustomNotFound v-if="noResultsFound" :text="$t('noResultsFound')" />
      <div class="row">
        <div
          v-for="(serviceQueue, index) in serviceQueues.data"
          :key="index"
          class="mt-3 col-12"
        >
          <search-result
            :display-office="!isMultiOffice"
            :value="serviceQueue"
            @on-automatic-call="onAutomaticCall(serviceQueue)"
            @on-autoregister-configuration="
              onAutoregisterConfiguration(serviceQueue)
            "
            @on-available-hours="onAvailableHours(serviceQueue)"
            @on-business-days="onBusinessDays(serviceQueue)"
            @on-configuration="onConfiguration(serviceQueue)"
            @on-delete="openDeleteModal(serviceQueue)"
            @on-queues-to-move="onQueuesToMove(serviceQueue)"
            @on-services="onServices(serviceQueue)"
            @on-configure-notifications="onConfigureNotifications(serviceQueue)"
          />
        </div>
      </div>
      <custom-pagination
        v-if="serviceQueues.count > 0"
        id="pgPager"
        class="my-4"
        :data="serviceQueues"
        :page-size="pageSize"
        @pagination-go-page="search($event)"
        @pagination-rows-per-page="search(pageIndex, $event)"
      />
    </div>
    <custom-modal
      v-model="showServiceQueueFormModal"
      size="md"
      :title="$t('createServiceQueue')"
    >
      <service-queue-form
        v-if="showServiceQueueFormModal"
        :location-configuration-id="locationConfigurationId"
        @close="showServiceQueueFormModal = false"
        @submitted="search()"
      />
    </custom-modal>

    <custom-modal
      v-model="showServiceQueueAutomaticCallModal"
      size="lg"
      :title="$t('automaticServiceQueuesMaintenance')"
    >
      <service-queue-automatic-queues-form
        v-if="showServiceQueueAutomaticCallModal"
        v-model="editModel"
        @close="showServiceQueueAutomaticCallModal = false"
        @submitted="search()"
      />
    </custom-modal>

    <custom-modal
      v-model="showServiceQueueConfigurationModal"
      size="xl"
      :title="$t('serviceQueueConfiguration')"
    >
      <service-queue-configuration-form
        v-if="showServiceQueueConfigurationModal"
        v-model="editModel"
        @close="showServiceQueueConfigurationModal = false"
        @submitted="search()"
      />
    </custom-modal>

    <custom-modal
      v-model="showServiceQueueServicesModal"
      size="md"
      :title="$t('serviceQueueAssociatedServices')"
    >
      <associated-services-form
        v-if="showServiceQueueServicesModal"
        v-model="editModel"
        @close="showServiceQueueServicesModal = false"
        @submitted="search()"
      />
    </custom-modal>

    <custom-modal
      v-model="showServiceQueueToMoveModal"
      size="md"
      :title="$t('moveToAssociatedQueues')"
    >
      <associated-service-queues-form
        v-if="showServiceQueueToMoveModal"
        v-model="editModel"
        @close="showServiceQueueToMoveModal = false"
        @submitted="search()"
      />
    </custom-modal>

    <custom-modal
      v-model="showServiceQueueBusinessDaysModal"
      size="md"
      :title="$t('daysAvailableForWork')"
    >
      <service-queue-business-days-form
        v-if="showServiceQueueBusinessDaysModal"
        v-model="editModel"
        @close="showServiceQueueBusinessDaysModal = false"
        @submitted="search()"
      />
    </custom-modal>

    <custom-modal
      v-model="showServiceQueueAvailableHoursModal"
      size="md"
      :title="$t('workingHours')"
    >
      <service-queue-business-hours-form
        v-if="showServiceQueueAvailableHoursModal"
        v-model="editModel"
        @close="showServiceQueueAvailableHoursModal = false"
        @submitted="search()"
      />
    </custom-modal>

    <custom-modal
      v-model="showServiceQueueAutoRegisterConfigurationModal"
      size="xl"
      :title="$t('autoregisterConfiguration')"
    >
      <service-queue-auto-register-configuration-form
        v-if="showServiceQueueAutoRegisterConfigurationModal"
        v-model="editModel"
        @close="showServiceQueueAutoRegisterConfigurationModal = false"
        @submitted="search()"
      />
    </custom-modal>

    <BigLoader v-if="loading" />
    <AlertMessage
      v-if="!showMaintenance && !loading"
      :message="$t('selectOfficeForTheMaintenance')"
      style="margin-top: 150px"
    />
    <DeleteModal
      v-model="showDeleteModal"
      error-message="queueNameIsNotCorrect"
      :item="editModel"
      label="serviceQueueName"
      name="serviceQueueName"
      @on-confirm="onDelete()"
    />
  </section>
</template>

<script>

import sessionState from '@/mixins/sessionState';
import CustomPagination from '@/components/CustomPagination';
import CustomModal from '@/components/basics/modal/CustomModal';
import CustomNotFound from '@/components/basics/alerts/CustomNotFound';
import SearchResult from './components/SearchResult';
import FilterManager from '@/components/FilterManager';
import BigLoader from '@/components/BigLoader';
import contentHeader from '@/components/Header';
import SubHeader from '@/components/SubHeader';
import IdentityPermission from '@/constants/IdentityPermission';
import Separator from '@/components/Separator';
import { getCompanies as _getCompanies } from '@/services/CompanyService';
import { getLocationList as _getLocationList } from '@/services/LocationService';
import { getServiceQueues as _getServiceQueues, deleteServiceQueue as _deleteServiceQueue } from '@/services/ServicesService';
import { modulesName } from '@/store';
import AlertMessage from '@/components/basics/alerts/AlertMessage';

import ServiceQueueForm from './components/ServiceQueueForm';
import ServiceQueueBusinessDaysForm from './components/BusinessDays';
import DeleteModal from '@/components/DeleteModal';
import ServiceQueueBusinessHoursForm from './components/BusinessHours';
import ServiceQueueConfigurationForm from './components/Configuration';
import ServiceQueueAutoRegisterConfigurationForm from './components/AutoRegisterConfiguration';
import ServiceQueueAutomaticQueuesForm from './components/AutomaticQueues';
import AssociatedServiceQueuesForm from './components/AssociatedQueues';
import AssociatedServicesForm from './components/AssociatedServices';

import { mapGetters } from 'vuex';

export default {
  name: 'RegisteredServiceQueues',
  components: {
    CustomPagination,
    CustomNotFound,
    SearchResult,
    FilterManager,
    contentHeader,
    SubHeader,
    Separator,
    AlertMessage,
    BigLoader,
    ServiceQueueForm,
    ServiceQueueBusinessDaysForm,
    ServiceQueueBusinessHoursForm,
    ServiceQueueConfigurationForm,
    ServiceQueueAutoRegisterConfigurationForm,
    ServiceQueueAutomaticQueuesForm,
    AssociatedServiceQueuesForm,
    AssociatedServicesForm,
    CustomModal,
    DeleteModal
  },
  mixins: [sessionState],
  data() {
    return {
      filters: {
        locations: [],
        companies: [],
      },
      selectedFilters: {
        location: [],
        company: [],
        name: '',
      },
      serviceQueues: {
        data: [],
        totalItemCount: 0,
        count: 0,
        pageCount: 0,
        currentPage: 1,
      },
      pageSize: 10,
      pageIndex: 1,
      noResultsFound: false,
      selectedServiceQueue: null,
      showMaintenance: false,

      showServiceQueueFormModal: false,
      showServiceQueueAutomaticCallModal: false,
      showServiceQueueConfigurationModal: false,
      showServiceQueueServicesModal: false,
      showServiceQueueToMoveModal: false,
      showServiceQueueBusinessDaysModal: false,
      showServiceQueueAvailableHoursModal: false,
      showServiceQueueAutoRegisterConfigurationModal: false,
      showDeleteModal: false,
      loading: false,
      editModel: null,
    };
  },
  mounted() {
    // load current state of this view
    const previousStateData = this.loadAndRemoveSessionData('serviceQueuesData');

    if (previousStateData != null) {
      Object.assign(this.$data, previousStateData);
    } else {
      if (!this.isMultiOffice) {
      this.getCompanies();
      this.showMaintenance = true;
      } 
      else if (this.isMultiOffice && this.currentActiveLocation) {
        this.showMaintenance = true;
      }
    }
  },
  computed: {
    ...mapGetters(modulesName.userModuleName, ['currentActiveLocation']),
    filtersConfig() {
      if (!this.isMultiOffice) {
        return [
          {
            rules: '',
            name: 'company',
            label: this.$t('company'),
            component: 'FilterMultiSelect',
            options: this.filters.companies,
            placeholder: `-- ${this.$t('all')} --`,
            fieldtext: 'text',
            fieldvalue: 'value',
            clearable: true,
            class: 'col-sm-12 col-md-4 col-lg-3',
            error: this.$t('mustselectcompany'),
            initialValue: this.selectedFilters.company,
            onChanged: this.onCompanyChanged,
          },
          {
            rules: '',
            name: 'location',
            label: this.$t('localization'),
            component: 'FilterMultiSelect',
            options: this.filters.locations,
            placeholder: `-- ${this.$t('all')} --`,
            fieldtext: 'name',
            fieldvalue: 'id',
            clearable: true,
            class: 'col-sm-12 col-md-4 col-lg-3',
            initialValue: this.selectedFilters.location,
            onChanged: this.onLocationChanged,
          },
          {
            rules: '',
            name: 'name',
            label: this.$t('name'),
            component: 'BaseInput',
            placeholder: this.$t('name'),
            fieldtext: 'name',
            fieldvalue: 'id',
            class: 'col-sm-12 col-md-4 col-lg-3',
            initialValue: this.selectedFilters.name,
            value: this.selectedFilters.name,
          },
        ];
      }

      return [
        {
          rules: '',
          name: 'name',
          label: this.$t('name'),
          component: 'BaseInput',
          placeholder: this.$t('name'),
          fieldtext: 'name',
          fieldvalue: 'id',
          class: 'col-sm-12 col-md-4 col-lg-3',
          initialValue: this.selectedFilters.name,
          value: this.selectedFilters.name,
        },
      ];
    },
    isMultiOffice() {
      return this.userHasPermissions(IdentityPermission.multi.office);
    },
    locationConfigurationId() {
      return this.isMultiOffice
        ? Number(this.currentActiveLocation.locationConfigurationId)
        : this.selectedFilters.location.length > 0
        ? this.selectedFilters.location[0].id
        : null;
    },
  },
  watch: {
    currentActiveLocation() {
      this.showMaintenance = false;
      this.loading = true;
      this.noResultsFound = false;
      setTimeout(() => {
        this.selectedFilters.name = '';
        this.serviceQueues = {
          data: [],
          totalItemCount: 0,
          count: 0,
          pageCount: 0,
          currentPage: 1,
        };
        this.loading = false;
        this.showMaintenance = true;
      }, 1200);
    },
  },
  methods: {
    async getCompanies() {
      await _getCompanies()
        .then((response) => {
          this.filters.companies = response.data || [];
        })
        .catch((error) => this.ShowErrorToast(error.response.data.message));
    },
    async getLocations(companyId) {
      await _getLocationList(companyId)
        .then((response) => {
          this.filters.locations = response.data;
        })
        .catch((error) => this.ShowErrorToast(error.response.data.message));
    },
    onCompanyChanged(value) {
      this.selectedFilters.location = [];
      this.selectedFilters.company = [];
      this.filters.locations = [];
      if (value) {
        this.selectedFilters.company = [value];
        this.getLocations(this.selectedFilters.company[0].value);
      }
    },
    onLocationChanged(value) {
      if (!value) return (this.selectedFilters.location = []);

      this.selectedFilters.location = [value];
    },
    async search(pageIndex = null, pageSize = null) {
      this.pageSize = pageSize || this.pageSize;
      this.pageIndex = pageIndex || this.pageIndex;

      const payload = {
        companyId:
          this.selectedFilters.company.length > 0
            ? this.selectedFilters.company[0].value
            : null,
        locationConfigurationId: this.locationConfigurationId,
        serviceQueueName: this.selectedFilters.name || null,
        pageIndex: this.pageIndex,
        pageSize: this.pageSize,
      };

      await _getServiceQueues(payload)
        .then(({ data }) => {
          this.serviceQueues = data;
          this.noResultsFound = !this.serviceQueues.count;
        })
        .catch((error) => this.ShowErrorToast(error.response.data.message));
    },
    async onAutomaticCall(data) {
      this.editModel = { ...data };
      this.showServiceQueueAutomaticCallModal = true;
    },
    async onConfiguration(data) {
      this.editModel = { ...data };
      this.showServiceQueueConfigurationModal = true;
    },
    async onServices(data) {
      this.editModel = { ...data };
      this.showServiceQueueServicesModal = true;
    },
    async onConfigureNotifications(data) {
      // Persist current state of this view
      this.saveSessionData('serviceQueuesData', Object.assign({}, this.$data));

      this.$router.push({name: 'ServiceQueueCustomNotifications', params: {id: data.serviceQueueId}})
    },
    async onQueuesToMove(data) {
      this.editModel = { ...data };
      this.showServiceQueueToMoveModal = true;
    },
    async onBusinessDays(data) {
      this.editModel = { ...data };
      this.showServiceQueueBusinessDaysModal = true;
    },
    async onAvailableHours(data) {
      this.editModel = { ...data };
      this.showServiceQueueAvailableHoursModal = true;
    },
    async onAutoregisterConfiguration(data) {
      this.editModel = { ...data };
      this.showServiceQueueAutoRegisterConfigurationModal = true;
    },
    openDeleteModal(data) {
      this.showDeleteModal = true;
      this.editModel = data;
    },
    onDelete() {
      _deleteServiceQueue(this.editModel.serviceQueueId)
        .then(async () => {
          this.ShowSuccessToast(this.$t('successfullyDeleted'));
          this.showDeleteModal = false;
          await this.search();
          this.editModel = null;
        })
        .catch((error) => this.ShowErrorToast(error.response.data.message));
    },
  },
};
</script>
<style lang="scss" scoped>
</style>
