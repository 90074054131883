<template>
  <validation-observer ref="serviceQueueGroupFormRef" novalidate>
    <div class="row">
      <div class="col-md-12">
        <validation-provider v-slot="{ errors }" rules="required|max:50">
          <base-input
            v-model="model.serviceQueueGroupNameESP"
            :error="errors[0]"
            :error-msg="$t('invalidField')"
            :label="$t('nameESP')"
            :max-length="50"
          />
        </validation-provider>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12">
        <validation-provider v-slot="{ errors }" rules="max:50">
          <base-input
            v-model="model.serviceQueueGroupNameENG"
            :error="errors[0]"
            :error-msg="$t('invalidField')"
            :label="$t('nameENG')"
            :max-length="50"
          />
        </validation-provider>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12 col-md-6">
        <b-form-checkbox
          v-model="model.isEnabled"
          class="mt-2 chk-available"
          switch
        >
          {{ $t('available') }}
        </b-form-checkbox>
      </div>
      <div class="col-sm-12 col-md-6">
        <button
          class="btn btn-primary float-right"
          variant="primary"
          @click="onSubmit()"
        >
          <i class="far fa-save" /> {{ $t('save') }}
        </button>
      </div>
    </div>
  </validation-observer>
</template>

<script>
import BaseInput from '@/components/BaseInput.vue';

import { createOrUpdateServiceQueueGroup as _createOrUpdateServiceQueueGroup } from '@/services/ServicesService';

export default {
  name: 'ServiceQueueGroupForm',
  components: { BaseInput },
  props: {
    value: {
      type: Object,
      required: false
    },
    companyId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {  
      model: !this.value ? {
        companyId: this.companyId,
        serviceQueueGroupNameESP: null,
        serviceQueueGroupNameENG: null,
        isEnabled: true,
      } : {
        serviceQueueGroupId: this.value.serviceQueueGroupId,
        companyId: this.value.companyId,
        serviceQueueGroupNameESP: this.value.serviceQueueGroupNameESP,
        serviceQueueGroupNameENG: this.value.serviceQueueGroupNameENG,
        isEnabled: this.value.isEnabled,
      },
    };
  },
  mounted() {
  },
  methods: {
    async onSubmit() {
      const isValid = await this.$refs.serviceQueueGroupFormRef.validate();

      if (!isValid) {
        return;
      }

      const payload = {
        serviceQueueGroupId: this.model.serviceQueueGroupId,
        companyId: this.model.companyId,
        serviceQueueGroupNameESP: this.model.serviceQueueGroupNameESP,
        serviceQueueGroupNameENG: this.model.serviceQueueGroupNameENG,
        isEnabled: this.model.isEnabled
      };

      await _createOrUpdateServiceQueueGroup(payload)
        .then(() => {
          this.ShowSuccessSaveToast();
          this.$emit('submitted');
          this.$emit('close');
        })
        .catch((error) => this.ShowErrorToast(error.response.data.message));
    },
  },
};
</script>
<style lang="scss" scoped>
</style>
