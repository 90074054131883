<template>
  <section>
    <content-header
      :title="$t('serviceQueueNotifications')"
    />
    <div class="container-fluid">
      <div class="card p-3" style="border: white">
        <validation-observer ref="form" novalidate>
          <div class="row">
            <div class="col-sm-12 col-md-4">
              <filter-select
                v-model="selectedNotificationType"
                fieldtext="name"
                fieldvalue="id"
                :is-multiple="false"
                :label="$t('type')"
                :options="notificationTypes || []"
                :placeholder="$t('select')"
                :return-object="true"
                :clearable="false"
                @change="onNotificationTypeChanged($event)"
              />
            </div>
            <div class="col-sm-12 col-md-4">
              <b-form-checkbox class="chkMiddle" v-model="isEnglishMode" switch>
                {{ $t('showEnglish') }}
              </b-form-checkbox>
            </div>
          </div>
          <hr class="splitter"/>
          <div class="row">
            <div v-if="model.emailData" class="col-md-6 col-sm-12">
              <h4 class="title">Email</h4>
              <div class="card" style="border: white">
                <div class="row">
                  <div class="col-md-12">
                    <div class="form-group">
                      <div class="d-flex justify-content-between">
                        <label>{{ $t(isEnglishMode ? 'subjectEnglish' : 'subjectSpanish') }}:</label>
                      </div>
                      <validation-provider
                        v-slot="{ errors }"
                        rules="required|max:256"
                      >
                        <base-input
                          v-model="emailCurrentSubject"
                          :error="errors[0]"
                          :error-msg="$t('invalidField')"
                        />
                      </validation-provider>
                    </div>
                  </div>
                  <div class="col-md-12">
                    <div class="form-group">
                      <div class="d-flex justify-content-between">
                        <label>{{ $t(isEnglishMode ? 'textEnglish' : 'textSpanish') }}:</label>
                      </div>
                      <validation-provider
                        v-slot="{ errors }"
                        rules="required"
                      >
                        <editor
                          v-model="emailCurrentText"
                          :init="editorConfiguration"
                        />
                        <error
                          v-if="errors[0]"
                          class="mt-n1"
                          :message="$t('invalidField')"
                          variant="no-border no-background"
                        />
                      </validation-provider>
                    </div>
                  </div>
                  <div class="col-md-12">
                    <div class="form-group">
                      <div class="d-flex justify-content-between">
                        <label>{{ $t(isEnglishMode ? 'shortTextEnglish' : 'shortTextSpanish') }}:</label>
                      </div>
                      <editor
                        v-model="emailCurrentShortText"
                        :init="{...editorConfiguration, height: 150 }"
                      />
                    </div>
                  </div>
                  <div class="col-md-12">
                    <validation-provider
                      v-slot="{ errors }"
                      rules="max:2000"
                    >
                      <base-input
                        v-model="model.emailData.description"
                        :textarea="true"
                        :error="errors[0]"
                        :error-msg="$t('invalidField')"
                        :label="$t('description')"
                      />
                    </validation-provider>
                  </div>
                </div>
                <div class="row mt-2">
                  <div class="col-sm-12 col-md-12 d-flex">
                    <b-form-checkbox
                      v-model="model.emailData.enabled"
                      class="mt-2 chk-available mr-3"
                      switch
                    >
                      {{ $t('active') }}
                    </b-form-checkbox>
                  </div>
                </div>
              </div>
            </div>
            <hr class="splitter col-sm-splitter m-3" />
            <div v-if="model.smsData" class="col-md-6 col-sm-12">
              <h4 class="title">SMS</h4>
              <div class="card" style="border: white">
                <div class="row">
                  <div class="col-md-12">
                    <div class="form-group">
                      <div class="d-flex justify-content-between">
                        <label>{{ $t(isEnglishMode ? 'subjectEnglish' : 'subjectSpanish') }}:</label>
                      </div>
                      <validation-provider
                        v-slot="{ errors }"
                        rules="required|max:256"
                      >
                        <base-input
                          v-model="smsCurrentSubject"
                          :error="errors[0]"
                          :error-msg="$t('invalidField')"
                        />
                      </validation-provider>
                    </div>
                  </div>
                  <div class="col-md-12">
                    <div class="form-group">
                      <div class="d-flex justify-content-between">
                        <label>{{ $t(isEnglishMode ? 'textEnglish' : 'textSpanish') }}:</label>
                      </div>
                      <validation-provider
                        v-slot="{ errors }"
                        rules="required"
                      >
                        <textarea
                          v-model="smsCurrentText"
                          class="smsLongText"
                          rows="5"
                        />
                        <error
                          v-if="errors[0]"
                          class="mt-n1"
                          :message="$t('invalidField')"
                          variant="no-border no-background"
                        />
                      </validation-provider>
                    </div>
                  </div>
                  <div class="col-md-12">
                    <div class="form-group">
                      <div class="d-flex justify-content-between">
                        <label>{{ $t(isEnglishMode ? 'shortTextEnglish' : 'shortTextSpanish') }}:</label>
                      </div>
                      <textarea
                        v-model="smsCurrentShortText"
                        class="smsShortText"
                        rows="5"
                      />
                    </div>
                  </div>
                  <div class="col-md-12">
                    <validation-provider
                      v-slot="{ errors }"
                      rules="max:2000"
                    >
                      <base-input
                        v-model="model.smsData.description"
                        :textarea="true"
                        :error="errors[0]"
                        :error-msg="$t('invalidField')"
                        :label="$t('description')"
                      />
                    </validation-provider>
                  </div>
                </div>
                <div class="row mt-2">
                  <div class="col-sm-12 col-md-4">
                    <filter-select
                      v-model="selectedNotificationProvider"
                      fieldtext="name"
                      fieldvalue="id"
                      :is-multiple="false"
                      :label="$t('deliveryProvider')"
                      :options="notificationProviders || []"
                      :placeholder="$t('select')"
                      :return-object="true"
                      @change="onNotificationProviderChanged($event)"
                    />
                  </div>
                  <div class="col-sm-12 col-md-8">
                    <b-form-checkbox
                      v-model="model.smsData.enabled"
                      class="chk-available mr-3 chkMiddle"
                      switch
                    >
                      {{ $t('active') }}
                    </b-form-checkbox>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-12 my-5 text-right">
              <base-filled-button
                bg-color="#707070"
                class="mx-0 float-left"
                icon-class="fas fa-ban"
                :on-click="() => $router.push({ name: 'ServiceQueuesList' })"
                :text="$t('cancel')"
              />

              <button
                v-if="canSave"
                class="btn btn-outline-primary"
                variant="outline-primary"
                @click="onSubmit()"
              >
                <i class="far fa-save" /> {{ $t('save') }}
              </button>
            </div>
          </div>
        </validation-observer>
      </div>
    </div>
  </section>
</template>

<script>

import Editor from '@tinymce/tinymce-vue';
import BaseInput from '@/components/BaseInput.vue';
import contentHeader from '@/components/Header';
import { getNotificationProviders as _getNotificationProviders } from '@/services/NotificationService'
import { getCustomNotifications as _getCustomNotifications, 
         createOrUpdateCustomNotification as _createOrUpdateCustomNotification,
         getAvailableCustomNotificationTypes as _getAvailableCustomNotificationTypes 
} from '@/services/ServicesService';

export default {
  name: 'ServiceQueueCustomNotifications',
  components: {
    BaseInput,
    contentHeader,
    Editor
  },
  data() {
    return {
      serviceQueueId: null,
      isEnglishMode: false,
      model: {
        emailData: null,
        smsData: null
      },
      loading: false,
      notificationProviders: [],
      selectedNotificationProvider: [],
      notificationTypes: [],
      selectedNotificationType: null
    };
  },
  async mounted() {
    this.serviceQueueId = Number(this.$route.params.id);

    await this.getNotificationProviders();
    await this.getNotificationTypes();
  },
  computed: {
    emailCurrentSubject: {
      get: function () {
        if (this.isEnglishMode) {
          return this.model.emailData.subjectEN ?? '';
        } else {
          return this.model.emailData.subject ?? '';
        }
      },
      set: function (newVal) {
        if (this.isEnglishMode) {
          this.model.emailData.subjectEN = newVal;
        } else {
          this.model.emailData.subject = newVal;
        }
      },
    },
    emailCurrentText: {
      get: function () {
        if (this.isEnglishMode) {
          return this.model.emailData.textEN ?? '';
        } else {
          return this.model.emailData.text ?? '';
        }
      },
      set: function (newVal) {
        if (this.isEnglishMode) {
          this.model.emailData.textEN = newVal;
        } else {
          this.model.emailData.text = newVal;
        }
      },
    },
    emailCurrentShortText: {
      get: function () {
        if (this.isEnglishMode) {
          return this.model.emailData.shortTextEN ?? '';
        } else {
          return this.model.emailData.shortText ?? '';
        }
      },
      set: function (newVal) {
        if (this.isEnglishMode) {
          this.model.emailData.shortTextEN = newVal;
        } else {
          this.model.emailData.shortText = newVal;
        }
      },
    },
    smsCurrentSubject: {
      get: function () {
        if (this.isEnglishMode) {
          return this.model.smsData.subjectEN ?? '';
        } else {
          return this.model.smsData.subject ?? '';
        }
      },
      set: function (newVal) {
        if (this.isEnglishMode) {
          this.model.smsData.subjectEN = newVal;
        } else {
          this.model.smsData.subject = newVal;
        }
      },
    },
    smsCurrentText: {
      get: function () {
        if (this.isEnglishMode) {
          return this.model.smsData.textEN ?? '';
        } else {
          return this.model.smsData.text ?? '';
        }
      },
      set: function (newVal) {
        if (this.isEnglishMode) {
          this.model.smsData.textEN = newVal;
        } else {
          this.model.smsData.text = newVal;
        }
      },
    },
    smsCurrentShortText: {
      get: function () {
        if (this.isEnglishMode) {
          return this.model.smsData.shortTextEN ?? '';
        } else {
          return this.model.smsData.shortText ?? '';
        }
      },
      set: function (newVal) {
        if (this.isEnglishMode) {
          this.model.smsData.shortTextEN = newVal;
        } else {
          this.model.smsData.shortText = newVal;
        }
      },
    },
    editorConfiguration() {
      return {
        height: 300,
        menubar: false,
        plugins:
          'advlist autolink lists link image charmap print preview anchor formatpainter searchreplace visualblocks code fullscreen insertdatetime media table paste textcolor',
        toolbar:
          'undo redo | formatselect | forecolor bold italic underline | \
                    alignleft aligncenter alignright alignjustify | \
                    bullist numlist | link image',
      };
    },
    smsEditorConfiguration() {
      return {
        height: 300,
        menubar: false,
        format : 'text',
        plugins:
          'print preview searchreplace paste',
        toolbar:
          'undo redo',
      };
    },
    canSave() {
      return this.selectedNotificationType;
    }
  },
  methods: {
    async getNotificationTypes() {
      await _getAvailableCustomNotificationTypes()
        .then((response) => {
          this.notificationTypes = response.data || [];
        })
        .catch((error) => this.ShowErrorToast(error.response.data.message));
    },
    async getNotificationProviders() {
      await _getNotificationProviders()
        .then((response) => {
          this.notificationProviders = response.data || [];
        })
        .catch((error) => this.ShowErrorToast(error.response.data.message));
    },
    async getNotifications() {
      _getCustomNotifications(this.serviceQueueId, this.selectedNotificationType.id).then((response) => {
        this.model = response.data;

        this.selectedNotificationProvider = this.model.smsData.notificationProviderId ? this.notificationProviders.filter(x => x.id == this.model.smsData.notificationProviderId) : null;
      })
    },
    async onNotificationTypeChanged(value) {
      if (value) {
        this.selectedNotificationType = value;
        await this.getNotifications();
      }

      this.model = {
        emailData: null,
        smsData: null
      }
    },
    async onNotificationProviderChanged(value) {
      if (value) {
        this.selectedNotificationProvider = [value];
        this.model.smsData.notificationProviderId = value.id;
      } 
      else {
        this.selectedNotificationProvider = [];
        this.model.smsData.notificationProviderId = null;
      }
    },
    async onSubmit() {
      if (!(await this.$refs.form.validate())) return;

      let payload = { ...this.model };

      await _createOrUpdateCustomNotification(payload)
        .then(() => {
          this.$router.push({ name: 'ServiceQueuesList' })
        })
        .catch((error) => this.ShowErrorToast(error.response.data.message));
    }
  },
};
</script>
<style lang="scss" scoped>
  .chkMiddle {
    margin-top: 36px;
  }
  .splitter {
    border: 1px solid lightgray;
    width: 100%;
    margin: 10px 0;
  }
  .smsLongText {
    height: 295px;
    width: 100%;
    font-family: -apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,Oxygen,Ubuntu,Cantarell,'Open Sans','Helvetica Neue',sans-serif;
    line-height: 1.4;
  }

  .smsShortText {
    height: 145px;
    width: 100%;
    font-family: -apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,Oxygen,Ubuntu,Cantarell,'Open Sans','Helvetica Neue',sans-serif;
    line-height: 1.4;
  }

  @media only screen and (min-width: 768px) {
    .col-sm-splitter {
      display: none;
    }
  }
</style>
