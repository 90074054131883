import { apiTurnos } from '@/core/api/';
import { serialize } from 'object-to-formdata';

const qs = require('querystring');

export const getServiceTypesForSpaceCreation = (locationId) => {
  return apiTurnos.get(
    `/api/service/type/createspacelist?locationId=${locationId}`,
  );
};

export const getServices = (companyId) =>
  apiTurnos.get('api/service/getallbycompanyid', { params: { companyId } });

export const getAllServices = (companyId, serviceId, extraField1) => {
  return apiTurnos.get('api/service/GetAllByCompanyIdAndServiceId', {
    params: {
      companyId,
      serviceId,
      extraField1,
    },
  });
};

export const getAllServiceQueues = (companyId) => {
  return apiTurnos.get(`api/service/GetAllByCompanyId`, {
    params: { companyId },
  });
};

export const getWithServiceTypes = (locationConfigurationId) =>
  apiTurnos.get(`api/service/${locationConfigurationId}/type`);

export const getQueuesWithRelatedService = (
  location,
  specialField,
  screenServiceId,
) =>
  apiTurnos.get(
    `api/service/${location}/${specialField}/${screenServiceId}/services`,
  );

export const getQueuesForKioskScreenList = (
  locationId,
  specialField,
  kioskScreenId,
) =>
  apiTurnos.get(
    `/api/service/${locationId}/${specialField}/${kioskScreenId}/queue`,
  );

export const getForTechnicianByLocationConfigurationId = (
  locationConfigurationId,
) =>
  apiTurnos.get(
    `/api/service/GetAllForTechnicianByLocationConfigurationId/${locationConfigurationId}`,
  );

export const getQueueList = (
  locationConfigurationId,
  includeOnHoldServices = true,
  displayHiddenServiceQueuesForReports = null,
  appendLocation = false,
) => {
  return apiTurnos.get(`api/service/${locationConfigurationId}/list`, {
    params: {
      includeOnHoldServices,
      displayHiddenServiceQueuesForReports,
      appendLocation: appendLocation,
    },
  });
};

export const getQueueByMultiLocationList = (
  locationConfigurationIds,
  includeOnHoldServices = true,
  displayHiddenServiceQueuesForReports = null,
) => {
  return apiTurnos.get(`api/service/multilocation/list`, {
    params: {
      locationConfigurationIds,
      includeOnHoldServices,
      displayHiddenServiceQueuesForReports,
    },
    paramsSerializer: (params) => {
      return qs.stringify(params);
    },
  });
};

export const getQueueGroupByMultiLocationList = (
  locationConfigurationIds,
  appendLocation = false,
) => {
  return apiTurnos.get(`api/service/group/multilocation/list`, {
    params: {
      locationConfigurationIds,
      appendLocation,
    },
    paramsSerializer: (params) => {
      return qs.stringify(params);
    },
  });
};

export const getServiceGroupByMultiLocationList = (
  locationConfigurationIds,
  displayHiddenServiceQueuesForReports = null,
) => {
  return apiTurnos.get(`api/service/group/type/multilocation/list`, {
    params: {
      locationConfigurationIds,
      displayHiddenServiceQueuesForReports,
    },
    paramsSerializer: (params) => {
      return qs.stringify(params);
    },
  });
};

export const getServicesOverWaiting = (
  locationConfigurationId,
  includeOnHoldServices = true,
) => {
  return apiTurnos.get('api/service/overwait/list', {
    params: {
      locationConfigurationId,
      includeOnHoldServices,
    },
  });
};

export const getOverWaitingServiceTypes = (
  serviceQueueId,
  locationConfigurationId,
) => {
  return apiTurnos.get(
    `/api/service/${serviceQueueId}/overwaiting/servicetype/list`,
    {
      params: {
        locationConfigurationId,
      },
    },
  );
};

export const getServiceTypeOverWaiting = (
  serviceQueueId,
  locationConfigurationId,
) => {
  return apiTurnos.get(
    `/api/service/${serviceQueueId}/overwaiting/servicetype/list`,
    {
      params: {
        locationConfigurationId,
      },
    },
  );
};

export const getSubServicesByServiceQueueId = (serviceQueueId) => {
  return apiTurnos.get(`api/service/subservices/${serviceQueueId}`);
};

export const getServicesTransferTurnList = (
  locationConfigurationId,
  serviceQueueId,
) =>
  apiTurnos.get(
    `api/employee/turn/${locationConfigurationId}/servicequeue/${serviceQueueId}/list`,
  );

export const getServicesTypeTransferTurnList = (locationConfigurationId) =>
  apiTurnos.get(`api/employee/turn/${locationConfigurationId}/service/list`);

export const validateServiceQueue = (serviceQueueId) =>
  apiTurnos.get(`api/service/queue/${serviceQueueId}/check`);

export const validateServiceType = (serviceId) =>
  apiTurnos.get(`api/service/type/${serviceId}/check`);

export const getTypesConfiguredForServiceQueues = (payload) =>
  apiTurnos.get('api/service/queue/servicetypes', {
    params: payload,
    paramsSerializer: (params) => {
      return qs.stringify(params);
    },
  });

export const commentIsRequired = (serviceQueueId) =>
  apiTurnos.get(`api/service/${serviceQueueId}/commentrequired`);

export const getServiceQueues = (payload) =>
  apiTurnos.get('/api/service/search', { params: { ...payload } });

export const getServiceTypes = (payload) =>
  apiTurnos.get('/api/service/search/types', { params: { ...payload } });

export const createServiceType = (payload) =>
  apiTurnos.post('api/service/type', payload);

export const updateServiceType = (payload) =>
  apiTurnos.put(`api/service/type/${payload.serviceTypeId}`, payload);

export const getServiceTypesBusinessDays = (payload) =>
  apiTurnos.get(`/api/service/type/${payload}/businessdays`);

export const updateServiceTypesBusinessDays = (payload) =>
  apiTurnos.put(
    `/api/service/type/${payload.serviceTypeId}/businessdays`,
    payload,
  );

export const updateServiceTypesHours = (payload) =>
  apiTurnos.put(`/api/service/type/${payload.serviceTypeId}/hours`, payload);

export const updateServiceTypesKioskHours = (payload) =>
  apiTurnos.put(
    `/api/service/type/${payload.serviceTypeId}/kioskhours`,
    payload,
  );

export const cloneServiceType = (payload) =>
  apiTurnos.post(`/api/service/type/${payload.serviceTypeId}/clone`, payload);

export const getServiceType = (payload) =>
  apiTurnos.get(`/api/service/type/${payload}`);

export const deleteServiceType = (payload) =>
  apiTurnos.delete(`/api/service/type/${payload}/delete`);

export const getServiceTypesByLocation = (payload) =>
  apiTurnos.get(`api/service/type/bylocation`, { params: { ...payload } });

/* Start of service queue maintenance */

export const createOrUpdateServiceQueue = (payload) =>
  apiTurnos.post(`/api/service`, payload);

export const getAutomaticServiceQueues = (payload) =>
  apiTurnos.get(
    `/api/service/${payload.serviceQueueId}/${payload.locationConfigurationId}/automaticqueues`,
  );

export const updateAutomaticServiceQueues = (payload) =>
  apiTurnos.put(
    `/api/service/${payload.serviceQueueId}/${payload.locationConfigurationId}/automaticqueues`,
    payload.serviceIdsForAutoCall,
  );

export const getServiceQueueAvailableDays = (payload) =>
  apiTurnos.get(`/api/service/${payload.serviceQueueId}/availabledays`, {
    params: { ...payload },
  });

export const updateServiceQueueAvailableDays = (payload) =>
  apiTurnos.put(
    `/api/service/${payload.serviceQueueId}/availabledays`,
    payload,
  );

export const updateServiceQueueServiceHour = (payload) =>
  apiTurnos.put(`/api/service/${payload.serviceQueueId}/servicehour`, payload);

export const getServiceQueueConfiguration = (payload) =>
  apiTurnos.get(`/api/service/${payload.serviceQueueId}/configuration`, {
    params: { ...payload },
  });

export const updateServiceQueueConfiguration = (payload) =>
  apiTurnos.put(
    `/api/service/${payload.serviceQueueId}/configuration`,
    payload,
  );

export const getServiceQueueAutoRegisterConfiguration = (payload) =>
  apiTurnos.get(`/api/service/${payload.serviceQueueId}/autoregister`, {
    params: { ...payload },
  });

export const updateServiceQueueAutoRegisterConfiguration = (payload) =>
  apiTurnos.put(`/api/service/${payload.serviceQueueId}/autoregister`, payload);

export const getAvailableServicesForQueue = (payload) =>
  apiTurnos.get(`/api/service/${payload.serviceQueueId}/availableservices`, {
    params: { ...payload },
  });

export const updateServiceQueueAvailableServices = (payload) =>
  apiTurnos.put(
    `/api/service/${payload.serviceQueueId}/availableservices`,
    payload,
  );

export const getAvailableServicesQueuesToMove = (payload) =>
  apiTurnos.get(
    `/api/service/${payload.serviceQueueId}/availablequeuestomove`,
    { params: { ...payload } },
  );

export const updateAvailableServiceQueuesToMove = (payload) =>
  apiTurnos.put(
    `/api/service/${payload.serviceQueueId}/availablequeuestomove`,
    payload,
  );

export const getServiceQueue = (payload) =>
  apiTurnos.get(`/api/service/${payload}`);

export const cloneServiceQueue = (payload) =>
  apiTurnos.post(`/api/service/${payload.id}/clone`, payload);

export const deleteServiceQueue = (payload) =>
  apiTurnos.delete(`/api/service/${payload}/delete`);

/* End of service queue maintenance */

/* Start of service queue group maintenance */

export const createOrUpdateServiceQueueGroup = (payload) =>
  apiTurnos.post(`/api/service/group`, payload);

export const getServiceQueueGroups = (payload) => {
  return apiTurnos.get('/api/service/group/search', {
    params: payload,
    paramsSerializer: (params) => {
      return qs.stringify(params);
    },
  });
};

export const getServiceQueueGroup = (payload) =>
  apiTurnos.get(`/api/service/group/${payload}`);

export const deleteServiceQueueGroup = (payload) =>
  apiTurnos.delete(`/api/service/group/${payload}/delete`);

export const getServiceQueueGroupLinkedQueues = (payload) => {
  return apiTurnos.get(`/api/service/group/queues/search`, {
    params: payload,
    paramsSerializer: (params) => {
      return qs.stringify(params);
    },
  });
};

export const updateServiceQueueGroupLinkedQueues = (payload) =>
  apiTurnos.put(`/api/service/group/queues`, payload);

/* End of service queue maintenance */

/* Start of service type group maintenance */

export const createOrUpdateServiceTypeGroup = (payload) =>
  apiTurnos.post(`/api/service/type/group`, payload);

export const getServiceTypeGroups = (payload) =>
  apiTurnos.get('/api/service/type/group/search', { params: { ...payload } });

export const getAppointmentServiceTypeGroups = (payload) =>
  apiTurnos.get('/api/service/appointment/type/group/search', {
    params: { ...payload },
  });

export const getServiceTypeGroup = (payload) =>
  apiTurnos.get(`/api/service/type/group/${payload}`);

export const deleteServiceTypeGroup = (payload) =>
  apiTurnos.delete(`/api/service/type/group/${payload}/delete`);

export const getServiceTypeGroupLinkedServices = (payload) =>
  apiTurnos.get(`/api/service/type/group/services/search`, {
    params: { ...payload },
  });

export const updateServiceTypeGroupLinkedServices = (payload) =>
  apiTurnos.put(`/api/service/type/group/services`, payload);

/* End of service type maintenance */

export const getCodeServiceTypes = (payload) =>
  apiTurnos.get('/api/service/codeservicetypes/search', {
    params: { ...payload },
  });

export const addCodeServiceType = (payload) =>
  apiTurnos.post('/api/service/codeservicetypes', payload);

export const updateCodeServiceType = (payload) =>
  apiTurnos.put('/api/service/codeservicetypes', payload);

export const getCodeServiceTypesList = () =>
  apiTurnos.get('/api/service/codeservicetypes/list');

export const getCodeServiceTypesLocation = (payload) =>
  apiTurnos.get('/api/service/codeservicetypes/location/search', {
    params: { ...payload },
  });

export const addCodeServiceTypesByLocation = (payload) =>
  apiTurnos.post(
    `/api/service/codeservicetypes/location/${payload.locationConfigurationId}`,
    payload.id,
  );

export const updateCodeServiceTypesByLocation = (payload) =>
  apiTurnos.put('/api/service/codeservicetypes/location', payload);

export const deleteCodeServiceTypesByLocation = (payload) =>
  apiTurnos.delete(
    `/api/service/codeservicetypes/${payload.id}/location/${payload.locationConfigurationId}`,
  );

export const getCodeServiceTypesListByLocation = (locationConfigurationId) =>
  apiTurnos.get(
    `/api/service/codeservicetypes/${locationConfigurationId}/list`,
  );

export const getCustomNotifications = (serviceQueueId, notificationTypeId) =>
  apiTurnos.get(
    `/api/service/${serviceQueueId}/notifications/${notificationTypeId}`,
  );
export const createOrUpdateCustomNotification = (model) => {
  const payload = serialize(model, {
    indices: true,
    nullsAsUndefineds: true,
  });

  return apiTurnos.post(`/api/service/notifications`, payload, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};
export const getAvailableCustomNotificationTypes = () =>
  apiTurnos.get(`/api/service/notification/types`);
