<template>
  <div>
    <div class="row">
      <div class="col-sm-12 my-2">
        <span
          class="subtitle"
          v-html="
            `${$t(
              'automaticServiceQueuesMaintenanceMessage',
            )} - <b class='color-black'>[${value.serviceQueueName}]</b>`
          "
        />
      </div>
      <div class="col-md-12 text-right">
        <button class="btn btn-outline-primary" @click="onAdd()">
          <i class="fas fa-plus"></i> {{ $t('add') }}
        </button>
      </div>
      <div
        v-for="(item, i) in selectedServiceQueues"
        :key="i"
        class="col-md-12"
      >
        <div class="row">
          <div class="col-md-7">
            <filter-select
              v-model="item.id"
              :clearable="false"
              fieldtext="name"
              fieldvalue="id"
              :label="$t('serviceQueueName')"
              :options="availableServiceQueues"
            />
          </div>
          <div class="col-md-3">
            <filter-numeric
              v-model="item.priorityOrder"
              :max="100"
              :min="1"
              title="priorityOrder"
            />
          </div>
          <div class="col-md-2 text-right pt-1">
            <button class="btn btn-danger mt-4" @click="onRemove(i)">
              <i class="fas fa-trash" />
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12 mt-4">
        <base-filled-button
          bg-color="#707070"
          class="mx-0 float-left"
          icon-class="fas fa-ban"
          :on-click="() => $emit('close')"
          :text="$t('cancel')"
        />

        <base-filled-button
          class="float-right mr-0"
          icon-class="far fa-save"
          :on-click="() => onSubmit()"
          :text="$t('save')"
        />
      </div>
    </div>
  </div>
</template>

<script>
import {
  getAutomaticServiceQueues as _getAutomaticServiceQueues,
  updateAutomaticServiceQueues as _updateAutomaticServiceQueues,
} from '@/services/ServicesService';

export default {
  name: 'ServiceQueueAutomaticForm',
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      availableServiceQueues: [],
      selectedServiceQueues: [],
    };
  },
  async mounted() {
    await this.loadAutomaticServiceQueues();
  },
  methods: {
    async loadAutomaticServiceQueues() {
      await _getAutomaticServiceQueues({
        serviceQueueId: this.value.serviceQueueId,
        locationConfigurationId: this.value.locationConfigurationId,
      })
        .then((response) => {
          this.availableServiceQueues = response.data;
          this.selectedServiceQueues = this.availableServiceQueues
            .filter((x) => x.selected)
            .map((x) => ({
              ...x,
              priorityOrder: !x.priorityOrder ? 1 : x.priorityOrder,
            }));
        })
        .catch((error) => this.ShowErrorToast(error.response.data.message));
    },
    async onSubmit() {
      _updateAutomaticServiceQueues({
        serviceQueueId: this.value.serviceQueueId,
        locationConfigurationId: this.value.locationConfigurationId,
        serviceIdsForAutoCall: this.selectedServiceQueues
          .filter((x) => x.id)
          .map((x) => ({
            servicioIdAutoCall: x.id,
            priorityOrder: x.priorityOrder,
          })),
      }).then(() => {
        this.ShowSuccessSaveToast();
        this.$emit('submitted');
        this.$emit('close');
      });
    },
    onAdd() {
      this.selectedServiceQueues.push({
        id: null,
        priorityOrder: 1,
      });
    },
    onRemove(index) {
      this.selectedServiceQueues.splice(index, 1);
    },
  },
};
</script>

<style lang="scss" scoped>
.subtitle {
  color: #ff8105;
}

::v-deep .vs__search {
  font-size: 14px;
  font-weight: 400 !important;
}
</style>
