<template>
  <div class="mt-5 container-fluid">
    <div class="row">
      <content-header :title="$t('cloneQueue')" />
      <div class="col-md-12">
        <div class="card">
          <div class="card-body">
            <validation-observer ref="form" novalidate>
              <div class="row">
                <div v-if="!isMultiOffice" class="col-md-6">
                  <validation-provider v-slot="{ errors }" rules="required">
                    <filter-select
                      v-model="model.companyId"
                      :clearable="false"
                      :error="errors[0]"
                      :error-msg="$t('mustSelectOne')"
                      fieldtext="text"
                      fieldvalue="value"
                      :label="$t('company')"
                      :options="filters.companies"
                    />
                  </validation-provider>
                </div>
                <div v-if="!isMultiOffice" class="col-md-6">
                  <validation-provider v-slot="{ errors }" rules="required">
                    <filter-select
                      v-model="model.locationConfigurationId"
                      :clearable="false"
                      :error="errors[0]"
                      :error-msg="$t('mustSelectOne')"
                      fieldtext="name"
                      fieldvalue="id"
                      :label="$t('localization')"
                      :options="filters.locations"
                    />
                  </validation-provider>
                </div>
                <div class="col-md-6">
                  <validation-provider
                    v-slot="{ errors }"
                    rules="required|max:50"
                  >
                    <base-input
                      v-model="model.serviceQueueName"
                      :error="errors[0]"
                      :error-msg="$t('invalidField')"
                      :label="`${$t('serviceQueueName')} ESP`"
                    />
                  </validation-provider>
                </div>
                <div class="col-md-6">
                  <validation-provider
                    v-slot="{ errors }"
                    rules="max:50"
                  >
                    <base-input
                      v-model="model.serviceQueueNameEn"
                      :error="errors[0]"
                      :error-msg="$t('invalidField')"
                      :label="`${$t('serviceQueueName')} ENG`"
                    />
                  </validation-provider>
                </div>
                <div class="col-md-6">
                  <validation-provider
                    v-slot="{ errors }"
                    rules="max:50"
                  >
                    <base-input
                      v-model="model.servicioDescPAlias"
                      :error="errors[0]"
                      :error-msg="$t('invalidField')"
                      :label="`${$t('publicAliases')} ESP`"
                    />
                  </validation-provider>
                </div>
                <div class="col-md-6">
                  <validation-provider
                    v-slot="{ errors }"
                    rules="max:50"
                  >
                    <base-input
                      v-model="model.servicioDescPAliasEn"
                      :error="errors[0]"
                      :error-msg="$t('invalidField')"
                      :label="`${$t('publicAliases')} ENG`"
                    />
                  </validation-provider>
                </div>
                <div class="col-md-6">
                  <validation-provider v-slot="{ errors }" rules="max:3">
                    <base-input
                      v-model="model.startingLetter"
                      :error="errors[0]"
                      :error-msg="$t('invalidField')"
                      :label="$t('serviceQueueStartingLetter')"
                      :max-length="3"
                    />
                  </validation-provider>
                </div>
                <validation-provider
                  v-slot="{ errors }"
                  tag="div"
                  class="col-md-6"
                  rules="required|min_value: 1"
                >
                  <filter-numeric
                    v-model="model.startingPoint"
                    :clearable="false"
                    :error="errors[0]"
                    :error-msg="$t('invalidField')"
                    :min="1"
                    :max="99999999999"
                    :title="$t('serviceQueueStartingNumber')"
                  />
                </validation-provider>
                <div class="col-md-12">
                  <b-form-checkbox v-model="model.copyHours" switch>
                    {{ $t('copyBusinessHours') }}
                  </b-form-checkbox>
                </div>
                <div class="col-md-12">
                  <b-form-checkbox v-model="model.copyBusinessDays" switch>
                    {{ $t('copyWorkingDays') }}
                  </b-form-checkbox>
                </div>
                <div class="col-md-12">
                  <b-form-checkbox v-model="model.copyAttentionGoalTime" switch>
                    {{ $t('copyAttentionTargetTime') }}
                    {{
                      model.attentionGoalTime
                        ? ` - (${model.attentionGoalTime}) min`
                        : null
                    }}
                  </b-form-checkbox>
                </div>
                <div class="col-md-12">
                  <b-form-checkbox v-model="model.copyWaitingGoalTime" switch>
                    {{ $t('copyGoalWaitTime') }}
                    {{
                      model.waitingTime
                        ? ` - (${model.waitingTime}) min`
                        : null
                    }}
                  </b-form-checkbox>
                </div>
              </div>
            </validation-observer>
          </div>
        </div>
      </div>
      <div class="col-md-12 text-right mt-3">
        <base-filled-button
          bg-color="#707070"
          class="mx-0 float-left"
          icon-class="fas fa-ban"
          :on-click="() => $router.push({ name: 'ServiceQueuesList' })"
          :text="$t('cancel')"
        />

        <button class="btn btn-primary" @click="onSubmit()">
          <i class="far fa-save" /> {{ $t('save') }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import IdentityPermission from '@/constants/IdentityPermission';
import BaseInput from '@/components/BaseInput.vue';
import ContentHeader from '@/components/Header';
import { mapGetters } from 'vuex';
import { modulesName } from '@/store';
import { getCompanies as _getCompanies } from '@/services/CompanyService';
import { getLocationList as _getLocationList } from '@/services/LocationService';
import {
  getServiceQueue as _getServiceQueue,
  cloneServiceQueue as _cloneServiceQueue,
} from '@/services/ServicesService';

export default {
  name: 'ServiceQueueClone',
  components: {
    BaseInput,
    ContentHeader,
  },
  data() {
    return {
      model: {
        id: null,
        companyId: null,
        locationConfigurationId: null,
        serviceQueueName: null,
        serviceQueueNameEn: null,
        servicioDescPAlias: null,
        servicioDescPAliasEn: null,
        startingLetter: null,
        startingPoint: null,
        attentionGoalTime: null,
        waitingTime: null,

      },
      initialized: false,
      filters: {
        companies: [],
        locations: [],
      },
    };
  },
  computed: {
    ...mapGetters(modulesName.userModuleName, ['currentActiveLocation']),
    isMultiOffice() {
      return this.userHasPermissions(IdentityPermission.multi.office);
    },
  },
  watch: {
    'model.companyId'(value) {
      if (this.isMultiOffice) return;
      this.filters.locations = [];
      if (this.initialized) this.model.locationConfigurationId = null;
      if (!value) return;
      this.getLocations(value);
    },
  },
  async mounted() {
    const id = Number(this.$route.params.id);
    await _getServiceQueue(id).then(({ data }) => {
      this.model = {
        id,
        companyId: data.companyId,
        locationConfigurationId: data.locationConfigurationId,
        serviceQueueName: data.serviceQueueName
          ? `${data.serviceQueueName} - copia`
          : null,
        serviceQueueNameEn: data.serviceQueueNameEn
          ? `${data.serviceQueueNameEn} - copy`
          : null,
        servicioDescPAlias: data.servicioDescPAlias
          ? `${data.servicioDescPAlias} - copia`
          : null,
        servicioDescPAliasEn: data.servicioDescPAliasEn
          ? `${data.servicioDescPAliasEn} - copy`
          : null,
        startingPoint: data.startingPoint,
        startingLetter: data.startingLetter,
        attentionGoalTime: data.attentionGoalTime,
        waitingTime: data.waitingTime,
        copyHours: true,
        copyBusinessDays: true,
        copyWaitingGoalTime: true,
        copyAttentionGoalTime: true,
      };
    });
    if (!this.isMultiOffice) await this.getCompanies();
    setTimeout(() => {
      this.initialized = true;
    }, 300);
  },
  methods: {
    async getCompanies() {
      await _getCompanies()
        .then((response) => {
          this.filters.companies = response.data || [];
        })
        .catch((error) => this.ShowErrorToast(error.response.data.message));
    },
    async getLocations(companyId) {
      await _getLocationList(companyId)
        .then((response) => {
          this.filters.locations = response.data;
        })
        .catch((error) => this.ShowErrorToast(error.response.data.message));
    },
    async onSubmit() {
      if (!(await this.$refs.form.validate())) return;
      const model = {
        ...this.model,
        locationConfigurationId: this.isMultiOffice
          ? Number(this.currentActiveLocation.locationConfigurationId)
          : this.model.locationConfigurationId,
      };
      await _cloneServiceQueue(model).then(() => {
        this.ShowSuccessSaveToast();
        this.$router.push({ name: 'ServiceQueuesList' });
      });
    },
  },
};
</script>
