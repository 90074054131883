<template>
  <div>
    <div class="row">
      <div class="col-sm-12 mt-2">
        <filter-multi-select
          :allow-select-all="false"
          fieldtext="name"
          fieldvalue="id"
          :is-multiple="true"
          :label="$t('associatedServicesList')"
          :options="model.availableServiceTypes"
          :placeholder="`-- ${$t('select')} --`"
          :value="model.selectedServiceTypes"
          @change="onSelectionChanged($event)"
        />
      </div>
    </div>
    <div class="row">
      <div class="col-md-12 mt-4">
        <base-filled-button
          bg-color="#707070"
          class="mx-0 float-left"
          icon-class="fas fa-ban"
          :on-click="() => $emit('close')"
          :text="$t('cancel')"
        />

        <base-filled-button
          class="float-right mr-0"
          icon-class="far fa-save"
          :on-click="() => onSubmit()"
          :text="$t('save')"
        />
      </div>
    </div>
  </div>
</template>

<script>
import {
  getAvailableServicesForQueue as _getAvailableServicesForQueue,
  updateServiceQueueAvailableServices as _updateServiceQueueAvailableServices,
} from '@/services/ServicesService';

import FilterMultiSelect from '@/components/FilterMultiSelect';

export default {
  name: 'AssociatedServicesForm',
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  components: {
    FilterMultiSelect,
  },
  data() {
    return {
      model: {
        availableServiceTypes: [],
        selectedServiceTypes: [],
      },
    };
  },
  async mounted() {
    await this.loadAutomaticServiceQueues();
  },
  methods: {
    async loadAutomaticServiceQueues() {
      await _getAvailableServicesForQueue({
        serviceQueueId: this.value.serviceQueueId,
        locationConfigurationId: this.value.locationConfigurationId,
      })
        .then((response) => {
          this.model.availableServiceTypes = response.data;
          this.model.selectedServiceTypes =
            this.model.availableServiceTypes.filter((x) => x.selected);
        })
        .catch((error) => this.ShowErrorToast(error.response.data.message));
    },
    async onSubmit() {
      _updateServiceQueueAvailableServices({
        serviceQueueId: this.value.serviceQueueId,
        ServiceIds: this.model.selectedServiceTypes.map((x) => x.id),
      }).then(() => {
        this.ShowSuccessSaveToast();
        this.$emit('submitted');
        this.$emit('close');
      });
    },
    onSelectionChanged(value) {
      this.model.selectedServiceTypes = value;
    },
  },
};
</script>

<style lang="scss" scoped>
.subtitle {
  color: #ff8105;
}
</style>

