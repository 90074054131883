<template>
  <div class="col-md-12">
    <div class="row my-3">
      <div class="col-md-3">
        <b-form-checkbox v-model="model.isVisibleSunday">
          {{ $t('sunday') }}
        </b-form-checkbox>
      </div>
      <div class="col-md-3">
        <b-form-checkbox v-model="model.isVisibleMonday">
          {{ $t('monday') }}
        </b-form-checkbox>
      </div>
      <div class="col-md-3">
        <b-form-checkbox v-model="model.isVisibleTuesday">
          {{ $t('tuesday') }}
        </b-form-checkbox>
      </div>
      <div class="col-md-3">
        <b-form-checkbox v-model="model.isVisibleWednesday">
          {{ $t('wednesday') }}
        </b-form-checkbox>
      </div>
      <div class="col-md-3">
        <b-form-checkbox v-model="model.isVisibleThursday">
          {{ $t('thursday') }}
        </b-form-checkbox>
      </div>
      <div class="col-md-3">
        <b-form-checkbox v-model="model.isVisibleFriday">
          {{ $t('friday') }}
        </b-form-checkbox>
      </div>
      <div class="col-md-3">
        <b-form-checkbox v-model="model.isVisibleSaturday">
          {{ $t('saturday') }}
        </b-form-checkbox>
      </div>
      <div class="col-md-12 mt-4">
        <base-filled-button
          bg-color="#707070"
          class="mx-0 float-left"
          icon-class="fas fa-ban"
          :on-click="() => $emit('close')"
          :text="$t('cancel')"
        />

        <base-filled-button
          class="float-right mr-0"
          icon-class="far fa-save"
          :on-click="() => onSubmit()"
          :text="$t('save')"
        />
      </div>
    </div>
  </div>
</template>

<script>
import {
  getServiceQueueAvailableDays as _getServiceQueueAvailableDays,
  updateServiceQueueAvailableDays as _updateServiceQueueAvailableDays,
} from '@/services/ServicesService';

export default {
  name: 'ServiceQueueBusinessDaysForm',
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      model: {
        isVisibleMonday: false,
        isVisibleTuesday: false,
        isVisibleWednesday: false,
        isVisibleThursday: false,
        isVisibleFriday: false,
        isVisibleSaturday: false,
        isVisibleSunday: false,
      },
    };
  },
  async mounted() {
    await this.loadBusinessDays();
  },
  methods: {
    async loadBusinessDays() {
      await _getServiceQueueAvailableDays({
        serviceQueueId: this.value.serviceQueueId,
        locationConfigurationId: this.value.locationConfigurationId,
      })
        .then((response) => (this.model = { ...this.model, ...response.data }))
        .catch((error) => this.ShowErrorToast(error.response.data.message));
    },
    async onSubmit() {
      _updateServiceQueueAvailableDays({
        ...this.model,
        serviceQueueId: this.value.serviceQueueId,
        locationConfigurationId: this.value.locationConfigurationId,
      }).then(() => {
        this.ShowSuccessSaveToast();
        this.$emit('submitted');
        this.$emit('close');
      });
    },
  },
};
</script>



